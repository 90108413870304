import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Divider, Box } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import RippleButton from '../../../../ReuseableComponets/RippleButton';
import { formatMobileNumber } from '../../../../utils/Commonfunction';


const EventBox = styled(Box)`
  padding: 4px;

`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  
`;

const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  text-decoration: ${({ status }) => status === 'Completed' && 'line-through'};
  margin-top: 5px;
  font-size: 12px;
  overflow: hidden;
  width:100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EventDetails = ({ event }) => {
  const navigate = useNavigate();
  return (
    <div onClick={(e) => e.stopPropagation()} style={{ marginTop: '5px', marginBottom: '5px' }}>
      <EventBox>
        <Typography variant="body2" color="textSecondary" style={{ color: '#0F52BA', fontSize: '12px' }}>
          {`${moment(event?.start).format('DD MMM, hh:mm A')} - ${moment(event?.end).format('DD MMM, hh:mm A')}`}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <div>
            <EventTitle>{event?.title}</EventTitle>
            {event?.data?.type === 'multivist' && event?.data?.description && (
              <StyledTypography variant="body2" color="textSecondary">
                {formatMobileNumber(event?.data?.description)}
              </StyledTypography>
            )}
            {event?.data?.email && (
              <StyledTypography variant="body2" color="textSecondary">
                {event?.data?.email}
              </StyledTypography>
            )}
            <StyledTypography variant="body2" color="textSecondary">
              {formatMobileNumber(event?.data?.customerNumber || event?.data?.number)}
            </StyledTypography>
          </div>
          <BookingStatus status={event?.data?.bookingStatus}>
            <span style={{ marginRight: '5px' }}>●</span>
            {event?.data?.type === 'task'
              ? 'Task'
              : event?.data?.type === 'event'
              ? 'Event'
              : event?.data?.bookingStatus === 'Waiting for confirmation'
              ? 'Open'
              : event?.data?.bookingStatus}
          </BookingStatus>
        </div>
        <Divider />
        <LocationTitle>
          {event?.data?.type === 'task' || event?.data?.type === 'event' ? 'Description' : 'Location'}
        </LocationTitle>
        <StyledTypography>{event?.description}</StyledTypography>
        {event?.data?.team?.length > 0 && (
          <>
            <Divider />
            <LocationTitle>Crew</LocationTitle>
            {event?.data?.team.map((item) => (
              <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                {item?.firstName} {item?.lastName}
              </StyledTypography>
            ))}
          </>
        )}
          {event?.data?.worker?.length > 0 && (
          <>
            <Divider />
            <LocationTitle>Crew</LocationTitle>
            {event?.data?.worker.map((item) => (
              <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                {item?.firstName} {item?.lastName}
              </StyledTypography>
            ))}
          </>
        )}
        {event?.data?.items?.length > 0 && (
          <>
            <Divider />
            <LocationTitle>Line items</LocationTitle>
            {event?.data?.items.map((item) => (
              <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                {item?.name} x {item?.quantity}
              </StyledTypography>
            ))}
          </>
        )}
      </EventBox>
      {(event?.data?.type === 'task' || event?.data?.type === 'event') ? null : (
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', paddingTop: '10px', position: 'fixed', bottom: 5, background: '#fff' }}>
          <RippleButton
            onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
            variant="contained"
            color="primary"
            style={{ width: '130px', fontWeight: '500', fontSize: '13px', background: '#fff', color: '#000', border: '1px solid #ddd' }}
          >
            Edit job
          </RippleButton>
          <RippleButton
            onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
            variant="contained"
            color="primary"
            style={{ width: '130px', fontWeight: '500', fontSize: '13px' }}
          >
            View details
          </RippleButton>
        </div>
      )}
    </div>
  );
};

// PropTypes
EventDetails.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    data: PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
      email: PropTypes.string,
      customerNumber: PropTypes.string,
      number: PropTypes.string,
      bookingStatus: PropTypes.string,
      team: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
        })
      ),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
        })
      ),
      referenceNo: PropTypes.string,
    }),
  }).isRequired,
};

export default EventDetails;
