import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment-timezone'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchAllContacts, fetchTeamData, getCalendarDataV2 } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components'
import 'react-tooltip/dist/react-tooltip.css'
import { useState } from 'react';
import { calendarTeamColor, colorArray, dayViewColor } from '../../../utils/Commonfunction';
import { Avatar, Box, Checkbox, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import ToggleTabs from '../../../ReuseableComponets/ToggleTab';
import { ArrowBackIosNew, ArrowForwardIos, CalendarMonth, HandymanOutlined, KeyboardArrowDown, TaskAltOutlined } from '@mui/icons-material';
import TopModal from '../../TopModal';
import AddTask from './AddTask';
import TopBarLoader from '../../../ReuseableComponets/TopBarLoader';
import { teamReducer } from '../../../store/Reducers/JobReducer';
import EventDetails from './Component/EventCard';
import { useRef } from 'react';
import { useEffect } from 'react';

function Bookings() {
    const modalRef = useRef(null);
    const [selectedEvent] = React.useState(null);
    const localizer = momentLocalizer(moment)
    const [isLoader, setIsLoader] = React.useState(false);
    const [isChangeLoader, setIsChangeLoader] = React.useState(false);
    const [event, setEvents] = React.useState([])
    const [AllData, setAllData] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [selected, setSelected] = useState({})
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Week")
    const [boolValue, setBoolValue] = useState(false);
    const [value, setValue] = useState({})
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [type, setType] = useState('week')
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [clickPosition, setClickPosition] = useState({ top: window.innerHeight / 2, left: window.innerWidth / 2 });
    const [state, jobDispatch] = React.useReducer(teamReducer, {
        selectedTeam: JSON.parse(localStorage.getItem('selectedTeam')) || [],
        isShowModal: false
    });

    const handleCheckboxClick = (item, index) => {
        jobDispatch({ type: 'TOGGLE_TEAM_MEMBER', payload: { ...item, index: index } });
    };

    const handleClickEvent = (event) => {
        jobDispatch({ type: 'TOGGLE_SHOW_MODAL' });

    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            jobDispatch({ type: 'TOGGLE_SHOW_MODAL' });
        }
    };


    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        const handleClick = (event) => {

            setClickPosition((prevPosition) => ({
                top: event.clientY,
                left: event.clientX,
            }));
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);



    const handleSelectSlot = (slotInfo, event) => {
        const isAllDayEvent = slotInfo.slots.length === 1 && moment(slotInfo.start).isSame(slotInfo.end, 'day');

        if (isAllDayEvent) {
            setAnchorEl(clickPosition);
        } else if (slotInfo.bounds) {
            setAnchorEl(slotInfo.bounds);
        } else {

            setAnchorEl(clickPosition);
        }

        setSelectedSlot(slotInfo);

    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (action) => {
        if (action === 'Add Task') {
            setBoolValue({ ...boolValue, isTask: true, type: action })
        }

        else if (action === 'Create Job') {
            setValue({
                date: selectedSlot?.start?.toISOString(),
                endDate: selectedSlot?.end?.toISOString(),
                time: selectedSlot?.start?.toISOString(),
                endTime: selectedSlot?.end?.toISOString(),
                edit: true

            })
            setIsModal(true)

        }
        else {
            setBoolValue({ ...boolValue, isTask: true, type: action })
        }
        handleMenuClose();
        // Add your logic to handle the specific action (Add Task, Create Job, etc.)
    };

    const fetchTeam = async (val) => {
        setSelected(val)
        if (val?.email === 'AllData') {
            setEvents(AllData)

            return
        }
        setEvents([])
        const res = await dispatch(fetchTeamData({
            "emails": [val?.email]
        }))
        let arr = []
        res.data?.[0]?.bookings.map((item) => {
            arr.push({
                data: item,
                title: item?.name || item?.to,
                start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                color: '#daf0ff',
                description: item?.customerAddress,
            })
        })
        setEvents(arr)
    }
    const getStartAndEndOfWeek = (date) => {
        const startOfWeek = moment(date).startOf('week').subtract(1, 'days').format('YYYY-MM-DD');
        const endOfWeek = moment(date).endOf('week').add(1, 'days').format('YYYY-MM-DD');
        return { startOfWeek, endOfWeek };
    };
    const getStartAndEndOfMonth = (date) => {
        const startOfWeek = moment(date).startOf('month').format('YYYY-MM-DD');
        const endOfWeek = moment(date).endOf('month').format('YYYY-MM-DD');
        return { startOfWeek, endOfWeek };
    };

    function findTeamColor(assignedTeam) {
        const selectedTeam = JSON.parse(localStorage.getItem('selectedTeam')) || [];
        for (const selectedMember of assignedTeam) {
            const matchingMember = selectedTeam.find(assigned => assigned.email === selectedMember.email);
            if (matchingMember) {
                const { index } = matchingMember;


                return {
                    original: calendarTeamColor[index]?.original || '#ffffff',
                    light: calendarTeamColor[index]?.light || '#ffffff'
                };
            }
        }

        return null;
    }


    const init = async () => {
        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(new Date());
        setIsLoader(true)
        const res1 = await dispatch(getCalendarDataV2(startOfWeek, endOfWeek))
        let arr = []
        let teamArr = []
        const dataarr = [...res1?.data?.detailData]

        dataarr?.map((item) => {
            const newDate = item?.timezone
                ? moment.tz(item?.date, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.date).format('DD-MMM-YYYY');

            const newEndDate = item?.timezone
                ? moment.tz(item?.endDate, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.endDate).format('DD-MMM-YYYY');

            const newTime = item?.timezone
                ? moment.tz(item?.time, item?.timezone).format('HH:mm')
                : moment(item?.time).format('HH:mm');

            const newEndTime = item?.timezone
                ? moment.tz(item?.endTime, item?.timezone).format('HH:mm')
                : moment(item?.endTime).format('HH:mm');

            let startdat = new Date(
                moment(newDate, 'DD-MMM-YYYY').year(),
                moment(newDate, 'DD-MMM-YYYY').month(),
                moment(newDate, 'DD-MMM-YYYY').date(),
                moment(newTime, 'HH:mm').hours(),
                moment(newTime, 'HH:mm').minutes()
            );

            if (!isNaN(startdat)) {

                arr.push({
                    data: item,
                    title: item?.name || item?.to || item?.title,
                    start: startdat,
                    end: item?.endDate && item?.endTime
                        ? new Date(
                            moment(newEndDate, 'DD-MMM-YYYY').year(),
                            moment(newEndDate, 'DD-MMM-YYYY').month(),
                            moment(newEndDate, 'DD-MMM-YYYY').date(),
                            moment(newEndTime, 'HH:mm').hours(),
                            moment(newEndTime, 'HH:mm').minutes()
                        )
                        : new Date(
                            moment(newDate, 'DD-MMM-YYYY').year(),
                            moment(newDate, 'DD-MMM-YYYY').month(),
                            moment(newDate, 'DD-MMM-YYYY').date(),
                            moment(newTime, 'HH:mm').hours() + 1, // Add 1 hour if no end time
                            moment(newTime, 'HH:mm').minutes()
                        ),
                    color: '#daf0ff',
                    description: item?.customerAddress || item?.task || item?.description,
                    status: item?.bookingStatus,
                    type: item?.type,
                    teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                    teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || ''
                });
            }
        })

        res1?.data?.team?.map((item, index) => {
            teamArr.push({ ...item, index: index })
        })
        setEvents(arr)
        setAllData(arr)
        setTeam([...teamArr])
        setIsLoader(false)
        const res10 = await dispatch(fetchAllContacts())
    }
    React.useEffect(() => {
        const invoiceParam = searchParams.get('type');

        if (invoiceParam) {
            setType(invoiceParam)
            setFilter(invoiceParam?.charAt(0).toUpperCase() + invoiceParam?.slice(1))

        }

        init()
    }, [])
    const handleSelectEvent = (event) => {

        setValue({})
        // setIsModal(true)
        // setSelectedEvent(event?.data);
        // console.log(event?.data?.referenceNo)
        // navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
    };

    const MyEvent = ({ event }) => {



        return (
            <TooltipStyled
                disableTouchListener
                disableInteractive={false}
                placement={'right-start'}
                PopperProps={{
                    disableInteractive: true,
                }}
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            maxHeight: '350px',

                            paddingBottom: (event?.data?.type == 'task' || event?.data?.type == 'event') ? "0px" : '40px',
                            width: '350px',

                            overflowY: 'scroll',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#fff',
                        },
                    },
                }}
                title={
                    <EventDetails event={event} />

                }
            >
                <EventDiv status={event?.status}>
                    {event?.title}
                    <Description>{event.description}</Description>
                    {
                        <TimeRange>{`${moment(event?.start).format('hh:mm A')} - ${moment(event?.end).format('hh:mm A')}`}</TimeRange>}
                </EventDiv>
            </TooltipStyled>
        );
    };



    const eventStyleGetter = (event, start, end, isSelected, type) => {
        const style = {
            backgroundColor: event?.teamColor ? event?.teamColor : selected?.index ? colorArray[selected?.index] : event?.type === "task" ? "#f9bfbf" : event?.type === "event" ? '#DDF2D1' : event?.type === 'multivist' ? "#d3d3d3" : '#D6E3F6',
            borderRadius: '5px',
            padding: '1px',
            opacity: 0.8,
            color: 'black',
            fontSize: '12px',
            marginLeft: '3px',
            opacity: 1.0,
            display: 'block',
            border: '0px solid #fff',
            borderLeft: selected?.index ? `4px solid ${colorArray[selected?.index]}` : event?.type === "task" ? `4px solid red` : event?.type === "event" ? `4px solid green` : event?.type === 'multivist' ? `4px solid #6C757D` : `4px solid ${palette.newBlue}`,


        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {


        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            setFilter('Week')
            setType(Views.WEEK)
            toolbar.onView('week');
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(toolbar.date);
            initRender(startOfWeek, endOfWeek);
        };

        const goToDay = () => {
            setFilter('Day')
            setType(Views.DAY)
            toolbar.onView('day');
        };
        const goToMonth = () => {
            setFilter('Month')
            setType(Views.MONTH)
            toolbar.onView('month');
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(toolbar.date);
            initRender(startOfWeek, endOfWeek);
        };

        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="rbc-toolbar">


                    <span style={{ display: 'flex', marginLeft: '20px' }} className="rbc-btn-group">
                        <button style={{ marginRight: '20px', borderRadius: '4px', border: '1.2px solid gray', fontSize: '14px', fontWeight: '500' }} type="button" onClick={goToToday}>
                            Today
                        </button>
                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('PREV')}>
                            <ArrowBackIosNew style={{ fontSize: '20px' }} />
                        </button>

                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', borderRadius: '4px', justifyContent: 'center', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('NEXT')}>
                            <ArrowForwardIos style={{ fontSize: '20px' }} />
                        </button>
                        <span style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '500' }} className="rbc-toolbar-label">
                            <CalendarMonth />
                            <div style={{ marginLeft: '8px' }}>{toolbar.label}</div>
                        </span>

                    </span>

                    <span className="rbc-toolbar-label"></span>

                </div>
                <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                    {(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 && <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center', }}>
                        {
                            (JSON.parse(localStorage.getItem('selectedTeam')) || [])?.slice(0, 4)?.map((item, index) =>
                                <Tooltip arrow title={`${item?.firstName} ${item?.lastName || ''}`}>
                                    <Avatar style={{ height: '28px', marginLeft: '4px', width: '28px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                        <Typography style={{ fontSize: '11px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                    </Avatar>
                                </Tooltip>

                            )
                        }
                        {(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length - 4 > 0 && (
                            <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: '#fff',
                                        color: '#000',
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                                        border: '1px solid #ddd',
                                    }
                                    }}}
                            arrow title={(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.map((item, index) =>
                                <AssignedSection style={{marginBottom:'5px'}}>
                                    <Avatar style={{ height: '25px', width: '25px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                        <Typography style={{ fontSize: '11px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                    </Avatar>
                                    <Typography style={{ fontSize: '13px', fontWeight: '500',color:'#000' }}>{item?.firstName} {item?.lastName || ''}</Typography>

                                </AssignedSection>

                            )}>
                                <Avatar style={{ height: '28px', marginLeft: '4px', width: '28px', background: palette.charcolegrey }}>
                                    <Typography style={{ fontSize: '11px', fontWeight: '600' }}> +{(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length - 4}</Typography>
                                </Avatar>
                            </Tooltip>
                        )}
                    </div>}
                                    <div style={{position:'relative',flexWrap:'wrap'}}>
                    <RippleButton
                        onClick={handleClickEvent}
                        style={{ marginRight: '20px', color: palette.newBlue, background: palette.white, border: '1.5px solid #ddd' }}
                    >
                        Crew  <KeyboardArrowDown style={{ fontWeight: '600', marginRight: '-10px' }} />
                    </RippleButton>
                    {state?.isShowModal &&
                        <>

                            <StyledPopupContainer
                                ref={modalRef}
                            >
                                <FilterMenu>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>Filters</h4>
                                        <ClearButton onClick={() => {
                                            localStorage.setItem('selectedTeam', JSON.stringify([]));
                                            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(toolbar.date);
                                            initRender(startOfWeek, endOfWeek);
                                            jobDispatch({ type: 'CLEAR_FILTER' });
                                            handleClickEvent()
                                        }} >Clear Filter</ClearButton>
                                    </div>
                                    <div style={{ maxHeight: '220px', overflowY: 'scroll' }}>
                                        {team?.length > 0 ?
                                            team?.map((item, index) =>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <AssignedSection>
                                                        <Avatar style={{ height: '25px', width: '25px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                                            <Typography style={{ fontSize: '11px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                                        </Avatar>
                                                        <Typography style={{ fontSize: '13px', fontWeight: '500', }}>{item?.firstName} {item?.lastName || ''}</Typography>

                                                    </AssignedSection>
                                                    <Checkbox
                                                        checked={state.selectedTeam.some(member => member.email === item.email)}
                                                        onChange={() => handleCheckboxClick(item, index)}

                                                    />
                                                </div>
                                            ) : <div>No crew member</div>
                                        }
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', marginBottom: '-10px' }}>
                                        <RippleButton style={{ marginRight: '15px', border: '1px solid #ddd', background: '#fff', color: '#000' }} onClick={handleClickEvent}>Cancel</RippleButton>
                                        <RippleButton onClick={() => {
                                            localStorage.setItem('selectedTeam', JSON.stringify(state.selectedTeam));
                                            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(toolbar.date);
                                            initRender(startOfWeek, endOfWeek);
                                            handleClickEvent()
                                        }}>Apply</RippleButton>
                                    </div>
                                </FilterMenu>
                            </StyledPopupContainer>

                        </>

                    }
                    </div>
                    <ToggleTabs
                        labels={['Week', 'Month', "Day", "Map"]}
                        selected={filter}
                        onToggle={(val) => {

                            if (val === 'Week') {
                                goToWeek()
                            }
                            else if (val === 'Month') {
                                goToMonth()
                            }
                            else if (val === 'Day') {
                                setFilter('Day')
                                navigate('/dashboard/timeline')
                            }
                            else {
                                setFilter('Map')
                                navigate('/dashboard/calander/map')

                            }
                        }}

                    />
                </div>

            </div>
        );
    };
    const dayHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD');
        const dayName = moment(label).format('ddd');
        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD');
        const dayName = moment(label).format('ddd');


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const MyTimeSlotWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '13px', marginTop: 5, borderTop: 'none', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayColumnWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '13px', marginTop: 5, flexDirection: 'row', display: 'flex', borderTop: 'none', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayFormat = 'dddd, MMMM D, YYYY';
    const monthFormat = 'MMMM YYYY';


    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {

                backgroundColor: isToday ? 'transparent' : 'white'

            },
        };
    }
    const MyEventWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                background: 'none',
                borderRadius: 4,
            }}
        >
            {children}
        </div>
    );

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);

    const timeGutterWraper = ({ event }) => {
        return (
            <div style={{ height: 'auto', width: '125%', fontSize: '13px', marginTop: '29px', paddingTop: '5px', borderTop: '1px solid #ddd', marginLeft: '-10px', marginRight: '-20px', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px' }}>
                All day
            </div>
        )
    }
    const CustomMonthEvent = ({ event }) => {
        return (
            <TooltipStyled
                disableTouchListener
                disableInteractive={false}
                placement={'right-start'}
                PopperProps={{
                    disableInteractive: true,
                }}
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            maxHeight: '350px',
                            paddingBottom: (event?.data?.type == 'task' || event?.data?.type == 'event') ? "0px" : '40px',
                            width: '350px',

                            overflowY: 'scroll',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#fff',
                        },
                    },
                }}
                title={
                    <EventDetails event={event} />

                }
            >
                <div style={{ padding: '0px', borderRadius: '6px', fontSize: '14px' }}>

                    {event?.title}
                </div>
            </TooltipStyled>
        );
    };
    const renderSelectedTimeInfo = () => {
        if (!selectedSlot) return '';
        const start = moment(selectedSlot.start).format('MMM DD, h:mm A');
        const end = moment(selectedSlot.end).format('MMM DD, h:mm A');
        return `${start} - ${end}`;
    };



    const initRender = async (start, end) => {

        setIsChangeLoader(true)
        const res1 = await dispatch(getCalendarDataV2(start, end))
        let arr = []
        let teamArr = []
        const dataarr = [...res1?.data?.detailData]

        dataarr?.map((item) => {
            const newDate = item?.timezone
                ? moment.tz(item?.date, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.date).format('DD-MMM-YYYY');

            const newEndDate = item?.timezone
                ? moment.tz(item?.endDate, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.endDate).format('DD-MMM-YYYY');

            const newTime = item?.timezone
                ? moment.tz(item?.time, item?.timezone).format('HH:mm')
                : moment(item?.time).format('HH:mm');

            const newEndTime = item?.timezone
                ? moment.tz(item?.endTime, item?.timezone).format('HH:mm')
                : moment(item?.endTime).format('HH:mm');

            let startdat = new Date(
                moment(newDate, 'DD-MMM-YYYY').year(),
                moment(newDate, 'DD-MMM-YYYY').month(),
                moment(newDate, 'DD-MMM-YYYY').date(),
                moment(newTime, 'HH:mm').hours(),
                moment(newTime, 'HH:mm').minutes()
            );

            if (!isNaN(startdat)) {

                arr.push({
                    data: item,
                    title: item?.name || item?.to || item?.title,
                    start: startdat,
                    end: item?.endDate && item?.endTime
                        ? new Date(
                            moment(newEndDate, 'DD-MMM-YYYY').year(),
                            moment(newEndDate, 'DD-MMM-YYYY').month(),
                            moment(newEndDate, 'DD-MMM-YYYY').date(),
                            moment(newEndTime, 'HH:mm').hours(),
                            moment(newEndTime, 'HH:mm').minutes()
                        )
                        : new Date(
                            moment(newDate, 'DD-MMM-YYYY').year(),
                            moment(newDate, 'DD-MMM-YYYY').month(),
                            moment(newDate, 'DD-MMM-YYYY').date(),
                            moment(newTime, 'HH:mm').hours() + 1, // Add 1 hour if no end time
                            moment(newTime, 'HH:mm').minutes()
                        ),
                    color: '#daf0ff',
                    description: item?.customerAddress || item?.task || item?.description,
                    status: item?.bookingStatus,
                    type: item?.type,
                    teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                    teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || ''
                });
            }
        })
        res1?.data?.team?.map((item, index) => {
            teamArr.push({ ...item, index: index + 1 })
        })
        setEvents(arr)
        setAllData(arr)
        setTeam([...teamArr])
        setIsChangeLoader(false)
    }


    const handleNavigate = (date, view) => {
        if (view === 'week') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);

            initRender(startOfWeek, endOfWeek);


        }
        else if (view === 'month') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(date);

            initRender(startOfWeek, endOfWeek);
        }

    };

    return (
        <Body >
            {isLoader && <LoaderOverlay>
                <StyledLoderBox>
                    <Loader />
                </StyledLoderBox>
            </LoaderOverlay>}
            {
                isChangeLoader && <TopBarLoader color="#FF5733" height="5px" speed={1} />
            }

            <div style={{ display: 'flex', width: '100%', marginLeft: '-1px', height: '100vh', paddingTop: '15px', }}>

                <CalanderTime
                    localizer={localizer}
                    events={event}

                    startAccessor="start"
                    view={type}
                    onSelectEvent={handleSelectEvent}
                    onNavigate={handleNavigate}
                    selectable
                    tooltipAccessor={null}
                    eventPropGetter={eventStyleGetter}
                    dayLayoutAlgorithm={'no-overlap'}

                    endAccessor="end"
                    style={{ height: '100%', width: '100%' }}
                    step={15} // This controls the slot duration
                    timeslots={2}
                    min={minTime}
                    max={maxTime}
                    popup={true}
                    onSelectSlot={handleSelectSlot}



                    formats={{
                        dayFormat: dayFormat,
                        monthHeaderFormat: monthFormat,
                        timeGutterFormat: 'h:mm A',
                        eventTimeRangeFormat: () => {
                            return ""
                        }





                    }}
                    allDayMaxRows={5}

                    allDayAccessor={(event) => console.log(" ")}
                    dayPropGetter={dayPropStyles}


                    components={{
                        toolbar: MyToolbar,
                        day: {
                            header: dayssHeaderContent
                        },


                        week: {
                            header: dayHeaderContent,






                        },
                        month: {
                            event: CustomMonthEvent
                        },

                        timeSlotWrapper: MyTimeSlotWrapper,
                        timeGutterHeader: timeGutterWraper,
                        dayColumnWrapper: dayColumnWrapper,


                        event: MyEvent,
                        eventContainerWrapper: MyEventWrapper,




                    }}
                />
                <Menu
                    anchorReference="anchorPosition"
                    anchorPosition={anchorEl ? { top: anchorEl.top, left: anchorEl.left } : undefined}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    sx={{
                        zIndex: 100000000000000
                    }}
                >
                    <MenuItem disabled>
                        <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                            {renderSelectedTimeInfo()}
                        </Typography>
                    </MenuItem>
                    <MenuItem style={{ marginBottom: '8px', fontSize: '14px' }} onClick={() => handleMenuClick('Add Task')}>
                        <TaskAltOutlined style={{ marginRight: '10px', fontSize: '20px' }} />
                        Create Task</MenuItem>
                    <MenuItem style={{ marginBottom: '8px', fontSize: '14px' }} onClick={() => handleMenuClick('Create Job')}>
                        <HandymanOutlined style={{ marginRight: '10px', fontSize: '20px' }} />
                        Create Job</MenuItem>
                    <MenuItem style={{ fontSize: '14px' }} onClick={() => handleMenuClick('Request Event')}>
                        <CalendarMonth style={{ marginRight: '10px', fontSize: '20px' }} />
                        Create Event</MenuItem>
                </Menu>



                {
                    ismodal && <FullScreenModal onClose={() => setIsModal(false)}>

                        <CreateBooking
                            value={value?.edit ? value : selectedEvent}
                            team={team}
                            refresh={() => init()}
                            serviceRequest={value?.edit ? false : true}
                            newService={true}
                            setCreateBookingModal={() => {
                                init()
                                setIsModal(false)
                            }}
                            onSubmitsw={(val, editValue) => {

                                // sendchat(val, editValue)
                                init()
                                setIsModal(false)



                            }
                            }

                        />
                    </FullScreenModal>
                }

                {
                    boolValue?.isTask && <TopModal
                        overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                        customStyle={{ width: '370px', maxHeight: '90%', background: '#fff' }}

                        onClose={() => setBoolValue({ ...boolValue, isTask: false })}
                    >
                        <AddTask
                            selectedSlot={selectedSlot}
                            team={team}
                            header={boolValue?.type}
                            onClose={() => setBoolValue({ ...boolValue, isTask: false })}
                            onSubmit={() => {
                                init()
                                setBoolValue({ ...boolValue, isTask: false })
                            }}
                        />
                    </TopModal>
                }
            </div>
        </Body>
    );
}
export default Bookings;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width:calc(100% - 240px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Loader = styled.div`
  border: 8px solid #ddd; /* Light grey background color */
  border-top: 8px solid ${palette.newBlue}; /* Blue color */
  border-radius: 50%;

  width: 35px;
  height: 35px;
  animation: ${spin} 1s linear infinite;
`;
const StyledLoderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
`;


const CalanderTime = styled(Calendar)`

.rbc-time-view .rbc-row{
    min-height:30px;
    
    max-height: calc(100vh - 200px); 
}
.rbc-time-slot {
  
    border: 0px solid #ccc; 
  }
  
  
.rbc-day-slot {
    position:none;
}
.rbc-timeslot-group {
    max-height:200px;
  }
  .rbc-selected-cell {
    background-color: rgba(0, 123, 255, 0.4) !important; /* Custom highlight color */
  }
  
  .rbc-active-range {
    background-color: rgba(255, 123, 0, 0.4) !important; /* Another option for active ranges */
  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    height: auto !important;
    display:flex;
   
    align-items:center;

}

 
  .rbc-events-container{
      overflow:hidden;
  }
  .rbc-month-row {
    min-height: 120px; /* Increase height to fit more events */
  }
  


`

const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;



const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  text-decoration: ${({ status }) => status === 'Completed' && 'line-through'};
  margin-top: 5px;
  font-size: 12px;
  padding-left:5px;
  overflow: hidden;
  width:100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const FilterMenu = styled.div`
min-width:270px;
padding: 20px 15px 25px 15px;
background:#fff;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);
border:1px solid #ddd;
`;



const ClearButton = styled.button`
  background-color: white;
  color: ${palette.newBlue};
  border: 1px solid ${palette.newBlue};
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const AssignedSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
  gap: 10px;
`;
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    top:45px;
    margin-left:-89px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`