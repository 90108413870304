import { Avatar, Card, CardContent, Divider, Menu, MenuItem, Select, StepLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import cross from '../../../assets/cross.png'
import Autocomplete from 'react-google-autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import invoiceimg from '../../../assets/invoice.png'
import quoteimg from '../../../assets/quote.png'
import booking1 from '../../../assets/booking1.png'
import allactivites from '../../../assets/add.png'
import trash from '../../../assets/trash.png'
import check from '../../../assets/check.png'
import TextField from '@mui/material/TextField';
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import Calendar from "react-calendar";
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../Modal';
import { addContactPhotos, addDetaisNote, addHasgtag, createNewCustomField, deleteAdditionalAddress, deleteAdditionalContact, deleteAdditionalNotes, deleteAddTask, DoneAddTask, getContactDetailV2, getUserDetails, saveAdditionalAddress, saveAdditionalContact, saveAdditionalNotes, submitAddTask, submitassignCustomField, submitassignTag, submitchangeStatus, submitEditContacts } from '../../../store/Actions/dashboard.action';
import moment from 'moment';
import RightModal from '../../RightSideModal';
import RightSideConversations from '../RightSideConversation';
import { colorThemes, formatMobileNumber, putCommaInvalue, TimeListAm, TimeListPM } from '../../../utils/Commonfunction';
import { Player } from '@lottiefiles/react-lottie-player';
import CreateInvoice from '../CreateInvoice';
import CreateQuote from '../CreateQuote';
import FullScreenModal from '../../FullScreenModal';
import { sendMessage } from '../../../store/Actions/Auth.action';
import plus from '../../../assets/plus.png'
import CreateBooking from '../CreateBooking';
import { DateRange } from 'react-date-range'
import { addDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { palette } from '../../../utils/Palette';
import { flag, InvoiceColor, jobColor, plusIcon, quoteIconColor } from '../../../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { AddOutlined, ArrowBackIos, Call, Cancel, Check, Construction, DeleteOutlined, EditOutlined, Handyman, Inbox, KeyboardArrowDown, LibraryBooks, LocalAtm, MessageOutlined, MyLocation, Paid, PaidRounded, PermPhoneMsg, RequestQuote, RequestQuoteOutlined, RequestQuoteRounded, Schedule, TaskAlt } from '@mui/icons-material';
import AutocompleteComponent from '../../../ReuseableComponets/CustomAutoComplete';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import TimelineContent from '@mui/lab/TimelineContent';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import CustomizedMenu from '../../../ReuseableComponets/MenuComponent';
import InputBox from '../../../ReuseableComponets/InputBox';
import DatePickerComponent from '../../../ReuseableComponets/DatePickerComponent';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CustomTabs from '../../../ReuseableComponets/CustomTab';
import TopModal from '../../TopModal';
import DeleteModal from './DeleteModal';
const filter = createFilterOptions();
const ContactDetails = (props) => {
    const navigate = useNavigate()
    let { number } = useParams()
    const ProgressData = [
        'Potential', 'In-Progress', 'Qualified', 'Bad fit', 'Customer', 'Not Interested'
    ]
    const ProgressDataColor = [
        '#007BFF', '#FFC107', '#B02A37', '#DC3545', '#28A745', '#FF851B'
    ]
    const [addresstitle, setAddressTitle] = useState("")
    const [boolValue, setBoolValue] = useState({
        description: false,
        edit: false,
        tag: false,
        customField: false,
        photo: false,
        task: false
    })
    const [address, setAddress] = React.useState({

        "street": "",
        "city": "",
        "state": "",
        "postalCode": "",
        "title": ''

    })
    const [editValue, setEditValue] = useState({
        "name": "",
        "email": "",
        "address": "",
        "to": "",
        "businessName": ''

    })

    const [isModalopen1, setIsModalOpen1] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [newCutomField, setNewCustomField] = useState({
        fieldName: '',
        fieldValue: ''
    })
    const [value, setValue] = React.useState(null);
    const [loader, setLoader] = React.useState(false);
    const [value1, setValue1] = React.useState(null);
    const [isDelete, setDelete] = useState({})
    const [additionalNotes, setAdditionalNotes] = useState('')
    const [additionalcallNotes, setAdditionalcallNotes] = useState('')
    const [isstartModalopen, setIsstartModalOpen] = useState(false)
    const [isBookingModalopen, setIsBookingModalOpen] = useState(false)
    const hashTags = useSelector(state => state?.auth?.userData?.hashTags)
    const customFieldData = useSelector(state => state?.auth?.userData?.customeFields)
    const contactDetail = useSelector(state => state?.auth?.contactDetail)
    const [customField, setDataCustomField] = useState(customFieldData)
    const [hastTag, setHasTag] = useState(hashTags)
    const [notes, setNotes] = useState('')
    const [selectHash, setSelectedHash] = useState([])
    const [selectCustomField, setCustomField] = useState([])
    const token = useSelector(state => state?.auth?.token)
    const [userDetails, setUserDetails] = useState({})
    const [status, setStatus] = useState('Status')
    const [searchData, setSearchData] = useState([])
    const [tempUserDetails, setTempUserDetails] = useState({})
    const [showMore, setShowMore] = useState(false);
    const itemsToShow = showMore ? selectCustomField.length : 3;
    const [selectedNumber, setSelectedNumber] = useState({})
    const [isModalopenInvoice, setModalOpenInvoice] = useState(false);
    const [isModalopenQuote, setModalOpenQuote] = useState(false);
    const [communication, setCommunication] = useState([])
    const [type, setType] = useState(false)
    const [modalData, setModalData] = useState({})
    const [selectedImage, setSelectedImage] = useState([]);
    const [postData, setpostData] = useState([]);
    const [taskData, setTaskData] = useState([])
    const [open, setOpen] = useState(false)
    const refOne = useRef(null)
    const [imagemodalOpen, setImageModalOpen] = useState(false)
    const [previewURL, setpreviewURL] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');
    const [additioncalContact, setAdditionalContact] = useState({
        "contactId": contactDetail?._id,
        "name": "",
        "email": "",
        "phoneNumber": ""
    })

    console.log('contactDetail', contactDetail)
    const handleChange = (panel) => {
        if (panel === expanded) {
            setExpanded(null)
        }
        else {
            setExpanded(panel);
        }
    };

    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection'
        }
    ])
    const [range1, setRange1] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
    ])
    const [values, setValues] = useState({
        date: '',
        time: '',
        taskDescription: '',
        "number": contactDetail?.number,
    })
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const dispatch = useDispatch()

    const init = async () => {
        setLoader(true)
        const res1 = await dispatch(getContactDetailV2(number))
        const res2 = await dispatch(getUserDetails())
        setCommunication(res1?.data[0]?.communication)
        console.log(res2, "reee")
        setHasTag(res2?.data?.hashTags)
        setDataCustomField(res2?.data?.customeFields)
        setTempUserDetails(res1?.data[0])
        setUserDetails(res1?.data[0]?.invoiceData)
        setSearchData(res1?.data[0]?.invoiceData)
        setSelectedHash(res1?.data[0]?.hashTags?.length > 0 ? res1?.data[0]?.hashTags : [])
        setCustomField(res1?.data[0]?.userFields?.length > 0 ? res1?.data[0]?.userFields : [])
        setNotes(res1?.data[0]?.notes)
        setSelectedImage(res1?.data[0]?.picUrl)
        setStatus(res1?.data[0]?.status || 'Status')
        setTaskData(res1?.data[0]?.taskIds)
        setEditValue({
            "name": res1?.data[0]?.name || "",
            "email": res1?.data[0]?.email || "",
            "address": res1?.data[0]?.address || "",
            "businessName": res1?.data[0]?.businessName || '',
            "to": res1?.data[0]?.number || '',
            "mainAddress": res1?.data[0]?.mainAddress || '',
        })
        setLoader(false)

    }
    const initComm = async () => {
        const res1 = await dispatch(getContactDetailV2(number))
        const res2 = await dispatch(getUserDetails())
        setCommunication(res1?.data[0]?.communication)
    }

    const filterData = [
        {
            name: 'All activities',
            image: allactivites
        },
        {
            name: 'Invoices',
            image: invoiceimg
        },
        {
            name: 'Quotes',
            image: quoteimg
        },
        {
            name: 'Bookings',
            image: booking1
        }
    ]

    useEffect(() => {

        init()
    }, []);

    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }

    const changeStatus = async (value) => {
        setStatus(value)
        const res = await dispatch(submitchangeStatus({
            "numberId": contactDetail?._id,
            "status": value
        }))



    }
    const assignTag = async () => {


        const res = await dispatch(submitassignTag({
            "numberId": contactDetail?._id,
            "hashTags": selectHash
        }))
        init()


    }
    const editContact = async () => {
        await dispatch(submitEditContacts({
            ...editValue,
            "phoneNumber": editValue?.to || "",
            "profilePhoto": "",
            "address": address?.address || editValue?.address,
            "mainAddress": address,
            "_id": number,

        }))
        init()
    }
    const assignCustomFiled = async () => {

        const res = await dispatch(submitassignCustomField({
            "numberId": contactDetail?._id,
            "customFields": selectCustomField
        }))
        init()


    }
    const addNotes = async () => {

        const res = await dispatch(addDetaisNote({
            "numberId": contactDetail?._id,
            "notes": notes
        }))
        init()


    }
    const submitTask = async () => {
        console.log(values, "dfjbfjfbjhb")
        const res = await dispatch(submitAddTask({ ...values, number: editValue?.to }))
        init()


    }
    const addNewHashTag = async (val) => {
        setSelectedHash([...selectHash, val])
        const res = await dispatch(addHasgtag({
            "hashTags": val
        }))
        setHasTag([...hastTag, val])



    }
    const sendchat = async (val) => {

        if (val) {
            await dispatch(sendMessage(
                {
                    "from": `+${localStorage.getItem('number')}`,
                    "to": editValue?.to,
                    "body": val
                }
            ))

        }
        init()
    }
    const editAdditionalContact = async () => {
        await dispatch(saveAdditionalContact({ ...additioncalContact, "contactId": number }))
        init()
    }
    const addPhotos = async () => {
        await dispatch(addContactPhotos(editValue?.to, postData))
        init()
    }

    const imageHandleChange = (e) => {
        const FileList = e.target.files
        const imageList = []

        Object.keys(FileList).map((item, index) => {
            const fileURL = URL.createObjectURL(FileList[item])
            imageList.push(fileURL)

        })
        setpostData([...postData, ...FileList])
        setSelectedImage([...selectedImage, ...imageList])
    }

    const menuItemsc = [
        {
            label: 'Edit',
            icon: <EditOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Edit clicked'),

        },
        {
            label: 'Delete',
            icon: <DeleteOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Duplicate clicked'),
        },
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case 'Waiting for confirmation': return '#fa811f';
            case 'Draft': return '#586171';
            case 'Rejected': return '#c0113d';
            default: return '#29740f';
        }
    };
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    return (
        <>
            <Body>
                {loader ? <div style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                    src={require('../../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </div> : <Main>
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => navigate(-1)}>
                                <ArrowBackIos style={{ fontSize: '16px' }} />
                            </CloseButton>

                        </div>
                    </HeaderContainer>
                    <Felxbox>
                        <div style={{ width: '450px', display: 'flex', flexDirection: 'column', }}>
                            <WrapperHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <ValueName style={{ fontWeight: '500', fontSize: '16px', color: palette.black }}>{tempUserDetails?.displayName ? tempUserDetails?.businessName : editValue?.name}</ValueName>
                                    <CustomizedMenu
                                        buttonStyle={{
                                            backgroundColor: 'transparent',
                                            minWidth: 'auto',
                                            padding: '2px',
                                            border: 'none',
                                        }}
                                        menuItems={menuItemsc}
                                        onClick={(lab) => {
                                            if (lab === 'Delete') {
                                                setDelete({
                                                    delete: true,
                                                    id: number,
                                                    type: 'Delete'

                                                })
                                            }
                                            else {
                                                setBoolValue({ ...boolValue, editcontact: true })
                                                setAddress({ ...tempUserDetails?.mainAddress, address: tempUserDetails?.address })
                                            }
                                        }}
                                    />
                                </div>
                                {editValue?.businessName && <p className='text'>{editValue?.businessName}</p>}
                                {editValue?.address && <p className='text'>{editValue?.address}</p>}
                                {editValue?.to && <p className='text'>{formatMobileNumber(editValue?.to)}</p>}
                                {editValue?.email && <p className='text'>{editValue?.email}</p>}

                            </WrapperHeader>
                            <Selectbox
                                style={{ borderRadius: '20px', marginTop: '0px', }}
                                placeholder='select' value={'Select an option'} displayEmpty

                                renderValue={() =>
                                    <div style={{ fontSize: '14px' }} className='keyContainer'>{status !== 'Status' && <a style={{ fontSize: '18px', color: status === 'Potential' ? ProgressDataColor[0] : status === 'In-Progress' ? ProgressDataColor[1] : status === 'Qualified' ? ProgressDataColor[2] : status === 'Bad fit' ? ProgressDataColor[3] : status === 'Customer' ? ProgressDataColor[4] : ProgressDataColor[5] }}> {'●  '}</a>} {status}</div>} onChange={(e) => {
                                        console.log(e.target)
                                        changeStatus(e.target.value)

                                    }}
                                sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,

                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,

                                    },
                                }}

                                MenuProps={{ // Add MenuProps to customize menu styling
                                    PaperProps: {
                                        style: {
                                            marginTop: '8px', // Adjust this value for the desired gap
                                        },
                                    },
                                }}
                            >
                                {ProgressData?.map((item, index) =>
                                    <StyledMenuItem key={index} value={item}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', height: '32px', alignItems: 'center', width: '100%' }}>

                                            <p style={{ fontSize: '14px', textAlign: 'start' }}><a style={{ fontSize: '18px', color: ProgressDataColor[index] }}>{'●  '}</a> {item}</p>



                                        </div>
                                    </StyledMenuItem>
                                )}
                            </Selectbox>

                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Add contact
                                    </h5>
                                    <EditContainer onClick={() => {
                                        setBoolValue({ ...boolValue, edit: true })
                                        setAdditionalContact({})
                                        }}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.edit ?
                                    contactDetail?.additionalContact?.map((item) =>
                                        <WrapperHeader style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '5px', paddingRight: '15px' }}>
                                                <div>
                                                    <ValueName style={{ fontWeight: '600', fontSize: '13px', marginBottom: '5px', color: palette.black }}>{item?.name}</ValueName>
                                                    {item?.number && <p className='text'>{formatMobileNumber(item?.number)}</p>}
                                                    {item?.companyTitle && <p className='text'>{item?.companyTitle}</p>}
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <Call onClick={() => {
                                                        const call = token.connect({
                                                            params: {
                                                                to: item?.number,
                                                                from: `+${localStorage.getItem('number')}`
                                                            }
                                                        })

                                                        dispatch({ type: "CONNECTION", payload: call })
                                                        dispatch({
                                                            type: "CALLDETAIL", payload: {
                                                                name: item?.name,
                                                                to: item?.number,
                                                            }
                                                        })
                                                        dispatch({ type: "CALLSHOW", payload: true })
                                                    }} style={{ fontSize: '16px', marginRight: '15px', cursor: 'pointer' }} />
                                                    <MessageOutlined onClick={() => {
                                                        setSelectedNumber(item)
                                                        setModalOpen(true)
                                                    }} style={{ fontSize: '16px', marginRight: '15px', cursor: 'pointer' }} />
                                                    <DeleteOutlined style={{ fontSize: '16px', cursor: 'pointer' }} onClick={async () => {
                                                        await dispatch(deleteAdditionalContact({
                                                            id: number,
                                                            contactId: item?._id
                                                        }))
                                                        init()
                                                    }} />
                                                </div>
                                            </div>
                                            {/* <MoreIcon style={{ width: '20px' }} src={notebook} /> */}
                                        </WrapperHeader>
                                    )

                                    :
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', width: '280px' }}>


                                        <InputBox
                                            header={"Name"}
                                            placeholder="Name"
                                            type="text"
                                            value={additioncalContact?.name}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, name: e.target.value
                                                })
                                            }}

                                        />

                                        <InputBox
                                            header={"Email"}
                                            placeholder="Email"
                                            type="text"
                                            value={additioncalContact?.email}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, email: e.target.value
                                                })
                                            }}

                                        />
                                        <Label style={{marginTop:'15px'}}>Phone number</Label>
                                        <PhoneFiled
                                          
                                            placeholder="Enter phone number"
                                            value={additioncalContact?.phoneNumber}
                                            onChange={(val) => {
                                              
                                                setAdditionalContact({
                                                    ...additioncalContact, phoneNumber:val
                                                })
                                              
                                            }}
                                          
                                            defaultCountry="US"
                                            countryCallingCodeEditable={false}
                                            style={{
                                                width: '308px', borderRadius: '5px', '&.PhoneInputInput': {
                                                   width:'100%'
                                                }
                                            }}

                                            international
                                            focusInputOnCountrySelection='true'
                                            tabIndex="0"
                                        />
                                        {/* <InputBox
                                            header={"Phone number"}
                                            placeholder="Phone number"
                                            type="text"
                                            value={additioncalContact?.phoneNumber}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, phoneNumber: e.target.value
                                                })
                                            }}

                                        /> */}
                                        <InputBox
                                            header={"Title"}
                                            placeholder="Sales & Marketing"
                                            type="text"
                                            value={additioncalContact?.title}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, title: e.target.value
                                                })
                                            }}

                                        />

                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '-20px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, edit: false })}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                // setEditValue({ ...editValue, ...editValue })
                                                editAdditionalContact()
                                                setBoolValue({ ...boolValue, edit: false })
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>
                                }
                            </StyleBox>

                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5>Tags</h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, tag: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.tag ?
                                    selectHash?.length > 0 ?
                                        <Wrapper>
                                            {selectHash?.map((item, index) =>
                                                <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>
                                                    {item}
                                                </WrapperContainer>
                                            )}
                                        </Wrapper> : null :
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                        <AutocompleteComponent
                                            value={value}
                                            onChange={(result) => {
                                                setValue('');
                                                setSelectedHash([...selectHash, result])
                                            }}
                                            onNewChange={async (result) => {
                                                setValue('');
                                                addNewHashTag(result)
                                            }}
                                            options={hastTag}
                                            placeholder="Search or create a tag"
                                            width={'98%'}
                                            marginLeft="0px"
                                            inputHeight="25px"
                                            inputFontSize="13px"

                                        />

                                        {selectHash?.length > 0 ? <Wrapper style={{ marginTop: '25px' }}>
                                            {selectHash?.map((item, index) =>
                                                <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>{item} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                    const arr = selectHash?.filter(obj => obj !== item)
                                                    setSelectedHash(arr)
                                                }} src={cross} /></WrapperContainer>
                                            )}
                                        </Wrapper> : null}
                                        <Divider style={{ marginTop: selectHash?.length > 0 ? '0px' : '30px' }} />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '10px', paddingRight: '15px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setSelectedHash(tempUserDetails?.hashTags)
                                                setBoolValue({ ...boolValue, tag: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                assignTag()
                                                setBoolValue({ ...boolValue, tag: false })
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>

                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Tasks
                                    </h5>
                                    <EditContainer onClick={() => {
                                        setValues({})
                                        setBoolValue({ ...boolValue, task: true })
                                    }}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.task ? taskData?.length > 0 ? <Wrapper>
                                    {taskData?.map((item, index) =>
                                        <div className='valueContainer' style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <div style={{ width: '90%' }}>
                                                <Label>{item?.task}</Label>
                                                <Label style={{ marginTop: '5px', fontSize: '12px', color: palette.grey }}> {`${moment(item?.date)?.format('DD MMM YYYY')} at ${item?.time}`}</Label>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <Check
                                                    onClick={async () => {
                                                        await dispatch(DoneAddTask(item?._id))
                                                        init()
                                                    }}
                                                    style={{ fontSize: '20px', color: '#000', marginLeft: '15px' }} src={check} />
                                                <DeleteOutlined style={{ fontSize: '20px', color: '#000', width: '18px', marginLeft: '20px' }} onClick={async () => {
                                                    await dispatch(deleteAddTask(item?._id))
                                                    init()
                                                }} src={trash} />
                                            </div>
                                        </div>
                                    )}
                                </Wrapper> : null :
                                    <div className='valueContainer' style={{ flexDirection: 'column', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                        <InputText
                                            placeholder="Description"
                                            type="text"
                                            value={values?.taskDescription}
                                            onChange={(e) => { setValues({ ...values, taskDescription: e.target.value }) }}
                                        />

                                        <div style={{ display: 'flex', marginTop: '15px', alignItems: 'center' }}>

                                            <DatePickerComponent
                                                label="Date"
                                                selectedDate={values?.date}
                                                onDateChange={(text) => setValues({ ...values, date: text })}
                                            />
                                            <div style={{ position: 'relative' }}>
                                                <Label style={{ marginLeft: '15px', fontWeight: '600' }}>Time</Label>
                                                <SelectTimebox
                                                    renderValue={() =>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                            {values?.time || 'Select time'}
                                                            <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                        </div>
                                                    }
                                                    defaultValue={"REAL_ESTATE"} onChange={(event) => setValues({ ...values, time: event.target.value })}>
                                                    {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                        <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                    )}
                                                </SelectTimebox>
                                                {/* <DateButton onClick={() => setIsstartModalOpen(true)}  >
                                                    {values?.time || "Time"}
                                                    <MoreIcon style={{ width: '15px' }} src={timeIcon} alt={timeIcon} />
                                                </DateButton> */}
                                            </div>
                                        </div>


                                        <div style={{ alignSelf: 'flex-end', display: 'flex', paddingTop: '20px', marginRight: '30px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setSelectedHash(tempUserDetails?.hashTags)
                                                setBoolValue({ ...boolValue, task: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton style={{ marginRight: -15 }} onClick={() => {
                                                submitTask()
                                                setBoolValue({ ...boolValue, task: false })
                                                // setValue({ ...value, ...editValue })
                                                // setEditDetail(false)
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>

                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Custom fields
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, customField: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.customField ? selectCustomField?.length > 0 ? <>
                                    {selectCustomField?.slice(0, itemsToShow).map((item, index) => (
                                        <CustomFieldWrapper>
                                            <Tooltip arrow placement='top' title={item?.fieldName}>
                                                <p className='fieldName'>{item?.fieldName}</p>
                                            </Tooltip>
                                            <p className='fieldValue'>{item?.fieldValue}</p>
                                        </CustomFieldWrapper>
                                    ))}
                                    {selectCustomField.length > 3 && (
                                        <OptionBox style={{ alignSelf: 'end', margin: '15px' }} onClick={toggleShowMore}>{showMore ? 'Show Less' : 'Show More'}</OptionBox>
                                    )}
                                </>
                                    : null
                                    : <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>

                                        <Autocomplete1
                                            value={value1}
                                            onChange={(event, newValue) => {
                                                console.log(newValue, "dfkvndkkjdvkjdvfjkn")
                                                const searchString = 'Create ';
                                                const replacement = '';
                                                const result = newValue?.fieldName?.replace(searchString, replacement)?.replace(/"/g, '');

                                                if (typeof newValue === 'string') {
                                                    setValue1(result);
                                                    setCustomField([...selectCustomField, { fieldName: 'cdsf' }])
                                                } else if (newValue && newValue.fieldName) {
                                                    // Create a new value from the user input
                                                    setCustomField([...selectCustomField, { fieldName: newValue?.fieldName, fieldValue: newValue?.fieldValue }])
                                                    setValue1(result);
                                                } else {
                                                    setValue1(result);

                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={customField}
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }

                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }

                                                return option;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option?.fieldName}</li>}
                                            sx={{
                                                width: 300, marginLeft: '12px', '& input': {
                                                    height: '5px',
                                                },
                                            }}
                                            freeSolo
                                            style={{ height: 30, }}
                                            renderInput={(params) => (
                                                <TextField style={{ fontSize: '14px' }} {...params} placeholder="Search custom field" />
                                            )}
                                        />
                                        <div style={{ marginTop: '20px', marginBottom: '10px', color: '#4169e1', cursor: 'pointer' }} onClick={() => setIsModalOpen1(true)}>Create new custom field</div>
                                        {selectCustomField?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '15px', paddingBottom: '20px', marginLeft: '12px', width: '325px' }}>

                                            {selectCustomField?.map((item, index) =>
                                                <div style={{ display: 'flex', alignItems: 'center', width: '310px', }}>
                                                    <div style={{ width: '100%', marginBottom: '10px' }}>
                                                        <Label>{item?.fieldName}</Label>
                                                        <InputBox
                                                            placeholder="Value"
                                                            type="text"
                                                            value={item?.fieldValue}
                                                            style={{ height: '15px', width: '100%' }}
                                                            onChange={(e) => {
                                                                const data = [...selectCustomField]
                                                                data[index].fieldValue = e.target.value
                                                                setCustomField(data)
                                                            }}
                                                        />
                                                    </div>
                                                    <DeleteOutlined style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '20px', marginTop: '10px' }} onClick={() => {
                                                        const data = [...selectCustomField]
                                                        data.splice(index, 1)
                                                        setCustomField(data)
                                                    }} /></div>
                                            )}
                                        </div> : null}
                                        <Divider />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setCustomField(tempUserDetails?.userFields)
                                                setBoolValue({ ...boolValue, customField: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                assignCustomFiled()
                                                setBoolValue({ ...boolValue, customField: false })
                                                // setValue({ ...value, ...editValue })
                                                // setEditDetail(false)
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>
                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Account Notes
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, description: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.description ? notes ? <p className='valueContainer' >{notes}</p> : null :
                                    <>
                                        <div className='valueContainer' style={{ flexDirection: 'column', alignItems: 'center', marginBottom: '10px', }}>

                                            <InputText
                                                placeholder="Note"
                                                type="text"
                                                value={notes}
                                                onChange={(e) => {
                                                    setNotes(
                                                        e.target.value
                                                    )
                                                }}

                                            />
                                        </div>
                                        <Divider />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', marginBottom: '10px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setNotes(tempUserDetails?.notes)
                                                setBoolValue({ ...boolValue, description: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                addNotes()
                                                setBoolValue({ ...boolValue, description: false })

                                            }}>Save</RippleButton>
                                        </div>
                                    </>
                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Photos / Attachments
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, photo: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {boolValue?.photo ? <>
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px', paddingRight: '10px' }}>
                                            {selectedImage?.map((item) =>
                                                <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                                    <PhotosStyle src={item} />
                                                    <div
                                                        onClick={() => {
                                                            const arr = selectedImage?.filter(obj => obj !== item)
                                                            setSelectedImage(arr)
                                                        }}
                                                        style={{ position: 'absolute', display: 'flex', top: 0, right: 5, background: 'white', borderRadius: 10 }}>
                                                        <MoreIcon style={{ width: '15px' }} src={cross} />
                                                    </div>
                                                </div>
                                            )}

                                            <FileInputButton htmlFor="fileInput">
                                                <MoreIcon src={plus} />
                                            </FileInputButton>
                                            <FileInput
                                                id="fileInput"
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                onChange={imageHandleChange}
                                            />
                                        </div>
                                        <Divider />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', paddingTop: '10px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setSelectedImage(tempUserDetails?.picUrl)
                                                setBoolValue({ ...boolValue, photo: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                addPhotos()
                                                setBoolValue({ ...boolValue, photo: false })
                                                // setValue({ ...value, ...editValue })
                                                // setEditDetail(false)
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>
                                </> : selectedImage.length > 0 ? <div className='valueContainer' style={{ flexDirection: 'column', }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', }}>
                                        {selectedImage?.map((item) =>
                                            <PhotosStyle onClick={() => {
                                                setpreviewURL(item)
                                                setImageModalOpen(true)
                                                // window.open(item)
                                            }} src={item} />
                                        )}

                                    </div></div> : null}
                            </StyleBox>
                        </div>
                        {

                            <StyleRight>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flex: "1", marginBottom: '20px', marginTop: '20px' }}>
                                    <div style={{ display: 'flex' }}>

                                        <OptionBox onClick={() => {
                                            const call = token.connect({
                                                params: {
                                                    to: editValue?.to,
                                                    from: `+${localStorage.getItem('number')}`
                                                }
                                            })

                                            dispatch({ type: "CONNECTION", payload: call })
                                            dispatch({
                                                type: "CALLDETAIL", payload: {
                                                    name: editValue?.name,
                                                    to: editValue?.to,
                                                }
                                            })
                                            dispatch({ type: "CALLSHOW", payload: true })
                                        }}>
                                            <Call style={{ marginRight: '10px', fontSize: '16px' }} />
                                            Call
                                        </OptionBox>
                                        <OptionBox onClick={() => {
                                            setSelectedNumber(editValue)
                                            setModalOpen(true)

                                        }}>
                                            <MessageOutlined style={{ marginRight: '10px', fontSize: '16px' }} />
                                            SMS
                                        </OptionBox>
                                    </div>
                                    <div>
                                        <OptionBox style={{ background: palette.newBlue, border: '1px solid #0F52BA', color: 'white', marginRight: '0px', width: '100px' }} onClick={handleClick}>
                                            <OptionIcon style={{ marginRight: '10px' }} src={plusIcon} />
                                            Create
                                        </OptionBox>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={open1}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            {<MenuItem style={{ color: palette.newBlue, fontSize: '14px' }} onClick={() => {
                                                setType(false)
                                                setModalData({})
                                                setModalOpenQuote(true)
                                                setAnchorEl(null)
                                            }}>
                                                <Icons style={{ width: '15px', height: '15px', marginRight: '12px' }} src={quoteIconColor} />
                                                Create quote
                                            </MenuItem>}
                                            <MenuItem style={{ color: palette.newBlue, fontSize: '14px' }} onClick={() => {
                                                setType(false)
                                                setIsBookingModalOpen(true)
                                                setAnchorEl(null)
                                            }}>
                                                <Icons style={{ width: '15px', height: '15px', marginRight: '12px' }} src={jobColor} />
                                                Create job
                                            </MenuItem>
                                            <MenuItem style={{ color: palette.newBlue, fontSize: '14px' }} onClick={() => {
                                                setType(false)
                                                setModalOpenInvoice(true)
                                                setAnchorEl(null)
                                            }}>
                                                <Icons style={{ width: '15px', height: '15px', marginRight: '12px' }} src={InvoiceColor} />
                                                Create invoice
                                            </MenuItem>



                                        </Menu>

                                        {/* <OptionBox onClick={() => {
                                    setType(false)
                                    setModalOpenQuote(true)
                                }}>
                                    <OptionIcon style={{ marginRight: '10px' }} src={payment} />
                                    Quote
                                </OptionBox>
                                <OptionBox onClick={() => {
                                    setType(false)
                                    setIsBookingModalOpen(true)
                                }}>
                                    <OptionIcon style={{ marginRight: '10px', }} src={payment} />
                                    Job
                                </OptionBox> */}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ddd' }}>

                                    <div style={{ display: 'flex' }}>
                                        {/* <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '25px', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <MoreIcon style={{ width: '15px', marginRight: '8px' }} src={flag} />
                                                    {filters}
                                                </div>
                                            )} onChange={(e) => {
                                                console.log(e.target)

                                            }}
                                            sx={{
                                                boxShadow: "none",
                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                    border: 0,

                                                },
                                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    border: 0,

                                                },
                                            }}
                                            MenuProps={{ // Add MenuProps to customize menu styling
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px', // Adjust this value for the desired gap
                                                    },
                                                },
                                            }}
                                        >
                                            {filterData?.map((item, index) =>
                                                <StyledMenuItem key={index} value={index}>
                                                    <ContainerBox
                                                        onClick={() => {
                                                            setFilters(item?.name)
                                                            if (item?.name === 'All activities') {
                                                                setSearchData(userDetails)
                                                            }
                                                            else if (item?.name === 'Invoices') {
                                                                const data = userDetails?.filter(item => item?.invoiceStatus)
                                                                setSearchData(data)
                                                            }
                                                            else if (item?.name === 'Quotes') {
                                                                const data = userDetails?.filter(item => item?.status)
                                                                setSearchData(data)
                                                            }
                                                            else {
                                                                const data = userDetails?.filter(item => item?.bookingStatus)
                                                                setSearchData(data)
                                                            }

                                                        }}
                                                        style={{ color: filters === item?.name ? '#4169e1' : '#000' }}>

                                                        <FilerIcon src={item?.image} />
                                                        {item?.name}</ContainerBox>
                                                </StyledMenuItem>
                                            )}
                                        </Selectbox> */}

                                        {/* <StyledDiv>
                                            <CalendarMonthOutlined style={{ fontSize:'16px', marginRight: '10px' }} src={calenda} />
                                            <CustomUrl
                                                value={range1[0]?.startDate ? `${format(range1[0]?.startDate, "MMM dd")} - ${format(range1[0].endDate, "MMM dd, yyyy")}` : 'Select date'}
                                                readOnly
                                                style={{ width: !range1[0]?.startDate && '70px' }}

                                                onClick={() => setOpen(open => !open)}
                                            />
                                        </StyledDiv> */}



                                        {open &&
                                            <>
                                                <StyleBox style={{ position: 'absolute', marginTop: '30px', background: 'white' }} ref={refOne}>
                                                    <DateRange
                                                        style={{ height: '220px' }}
                                                        onChange={item => setRange([item.selection])}
                                                        editableDateInputs={true}
                                                        moveRangeOnFirstSelection={false}

                                                        ranges={range}
                                                        months={1}
                                                        direction="horizontal"
                                                        className="calendarElement"
                                                    />
                                                    <ButtonBox>
                                                        <SendButton1 style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                            setOpen(false)
                                                            setRange1([{
                                                                startDate: '',
                                                                endDate: ''
                                                            }])
                                                            setRange([
                                                                {
                                                                    startDate: new Date(),
                                                                    endDate: addDays(new Date(), 3),
                                                                    key: 'selection'
                                                                }
                                                            ])
                                                            setSearchData(userDetails)
                                                        }}>Clear</SendButton1>
                                                        <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                                            setOpen(false)
                                                            setRange1([{
                                                                startDate: range?.[0]?.startDate,
                                                                endDate: range?.[0]?.endDate
                                                            }])
                                                            const filteredData = userDetails.filter(item => {
                                                                const itemDate = moment(item.date).format('YYYY-DD-MM');
                                                                return itemDate >= moment(range?.[0]?.startDate)?.format('YYYY-DD-MM') && itemDate <= moment(range?.[0]?.endDate)?.format('YYYY-DD-MM');
                                                            });
                                                            setSearchData(filteredData)
                                                            // const res = await dispatch(createNewCustomField({
                                                            //     "fields": [
                                                            //         newCutomField
                                                            //     ]
                                                            // }))
                                                            // setDataCustomField([...customField, newCutomField])
                                                            // setIsModalOpen1(false)
                                                        }}>Apply</SendButton1>
                                                    </ButtonBox>
                                                </StyleBox>
                                            </>
                                        }

                                    </div>


                                </div>
                                <CustomTabs
                                    tabsData={[
                                        {
                                            label: 'Communication',
                                            icon: <Inbox style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OptionBox onClick={() => {
                                                        if (communication?.some(item => item.type === 'note' && item.new === true)) {
                                                            // Do nothing if the object is already in the array
                                                        }
                                                        else {
                                                            setCommunication([{ type: 'note', new: true }, ...communication])
                                                            setAdditionalNotes('')
                                                        }
                                                    }}><AddOutlined style={{ fontSize: '16px', marginRight: '10px' }} /> Note</OptionBox>
                                                    <OptionBox onClick={() => {
                                                        if (communication?.some(item => item.type === 'note' && item.newCall === true)) {
                                                            // Do nothing if the object is already in the array
                                                        }
                                                        else {
                                                            setCommunication([{ type: 'note', newCall: true }, ...communication])
                                                            setAdditionalcallNotes('')
                                                        }
                                                    }}><AddOutlined style={{ fontSize: '16px', marginRight: '10px' }} /> Call log</OptionBox>
                                                </div>

                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        communication?.map((item, index) =>
                                                            item?.new || item?.newCall ? <TimelineItem key={index} style={{}}>
                                                                <TimelineSeparator>

                                                                    <TimelineDot style={{ width: '18px', height: '18px', background: item?.title === 'call Log' ? item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? 'red' : palette.green : palette.green : '' }}>
                                                                        {
                                                                            item?.newCall ? <PermPhoneMsg style={{ fontSize: '18px', }} /> : <LibraryBooks style={{ fontSize: '18px', }} />
                                                                        }
                                                                    </TimelineDot>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card onClick={() => {
                                                                        // if (item?.bookingStatus) {
                                                                        //     setModalData(item)
                                                                        //     setType(true)
                                                                        //     setIsBookingModalOpen(true)
                                                                        // }

                                                                    }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', cursor: 'pointer' }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <CommunicationInputText
                                                                                    placeholder={"Add a note..."}
                                                                                    value={item?.newCall ? additionalcallNotes : additionalNotes}
                                                                                    onChange={(e) => item?.newCall ? setAdditionalcallNotes(e.target.value) : setAdditionalNotes(e.target.value)}
                                                                                    style={{ border: 'none', width: '90%', boxShadow: 'none', '.&onfocus': 'none' }}
                                                                                />
                                                                                <Actions onClick={() => {
                                                                                    const newArray = [...communication]; // Make a copy to avoid mutating the original array
                                                                                    newArray.splice(index, 1);
                                                                                    setCommunication(newArray)

                                                                                }}>
                                                                                    <DeleteOutlined />
                                                                                </Actions>
                                                                            </Header>
                                                                            <Divider />
                                                                            <ButtonBox style={{ paddingBottom: '3px', marginBottom: '0px' }}>

                                                                                <RippleButton style={{ marginRight: '-10px' }} onClick={async () => {
                                                                                    await dispatch(saveAdditionalNotes({
                                                                                        "contactId": number,
                                                                                        "type": item?.newCall ? "call Log" : 'note',
                                                                                        "note": item?.newCall ? additionalcallNotes : additionalNotes
                                                                                    }))
                                                                                    init()

                                                                                }}>Save</RippleButton>
                                                                            </ButtonBox>

                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem> :

                                                                <TimelineItem key={index} style={{}}>
                                                                    <TimelineSeparator>

                                                                        <TimelineDot style={{ width: '18px', height: '18px', background: item?.type === 'call Log' ? palette.green : item?.status === 'Done' ? palette.orange : item?.type === 'note' ? palette.pup : item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? 'red' : palette.green : palette.green }}>
                                                                            {
                                                                                item?.type === 'call Log' ? <PermPhoneMsg style={{ fontSize: '18px', }} /> : item?.type === 'note' ? <LibraryBooks style={{ fontSize: '18px', }} /> : item?.status === 'Done' ? <TaskAlt style={{ fontSize: '18px', }} /> : <Call style={{ fontSize: '18px', }} />
                                                                            }
                                                                        </TimelineDot>
                                                                        <TimelineConnector sx={{ width: '1px' }} />
                                                                    </TimelineSeparator>
                                                                    <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                        <Card onClick={() => {
                                                                            // if (item?.bookingStatus) {
                                                                            //     setModalData(item)
                                                                            //     setType(true)
                                                                            //     setIsBookingModalOpen(true)
                                                                            // }

                                                                        }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', cursor: 'pointer' }}  >
                                                                            <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                                <Header>
                                                                                    {item?.type === 'call Log' || item?.type === 'note' ? <Label >{item?.note}</Label> : item?.status === 'Done' ? <Label ><span style={{ color: palette.grey }}>{`Task completed: `}</span> {item?.task}</Label> : <Status color={item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f'}>
                                                                                        {item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? `Missed call from ${item?.contactName}` : `Outgoing call to ${item?.contactName}` : `Incoming call from ${item?.contactName}`}
                                                                                    </Status>}
                                                                                    {item?.type === 'call Log' || item?.type === 'note' ? <Actions>

                                                                                        <DeleteOutlined style={{ cursor: 'pointer' }} onClick={async () => {
                                                                                            await dispatch(deleteAdditionalNotes({
                                                                                                id: number,
                                                                                                addressId: item?._id
                                                                                            }))
                                                                                            init()
                                                                                        }} />
                                                                                    </Actions> : null}
                                                                                </Header>

                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                    <div>

                                                                                        <Label style={{ color: palette.grey, marginBottom: '0px', fontSize: '12px' }}>{moment(item?.createdAt).format('DD MMM, hh:mm A')}</Label>
                                                                                    </div>

                                                                                </div>

                                                                                {item?.items?.length > 0 && <>
                                                                                    <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                        {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                        <h5>Line items</h5>
                                                                                    </LineItemsToggle>
                                                                                    {expanded === index && (
                                                                                        <LineItemsContainer>
                                                                                            {item?.items?.map((item, idx) => (
                                                                                                <LineItem key={idx}>
                                                                                                    <div>
                                                                                                        <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                        <p className="description">{item?.description}</p>
                                                                                                    </div>
                                                                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                                </LineItem>
                                                                                            ))}
                                                                                        </LineItemsContainer>
                                                                                    )}
                                                                                </>}
                                                                            </CardContent>
                                                                        </Card>
                                                                    </TimelineContent>
                                                                </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        },
                                        {
                                            label: 'Addresses',
                                            icon: <LocationOnIcon style={{ fontSize: '16px' }} />,
                                            content: <>
                                                <Card style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', marginBottom: '20px', border: '1px solid #f3f3f3', paddingBottom: '0px', paddingTop: '0px', marginLeft: '20px', marginRight: '20px' }}  >
                                                    <CardContent style={{ padding: '8px' }} >
                                                        {!boolValue?.address ? <Header onClick={() => setBoolValue({ ...boolValue, address: true })} style={{ justifyContent: 'center', cursor: 'pointer', alignItems: 'center', fontWeight: '600' }}>
                                                            <AddOutlined style={{ fontSize: '18px', marginRight: '5px' }} />
                                                            Add new address
                                                        </Header> :
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5%' }}>
                                                                <InputBox
                                                                    header={"Name"}
                                                                    value={addresstitle}

                                                                    style={{ display: 'flex', width: '90%', alignSelf: 'center', }}
                                                                    id="outlined-basic"
                                                                    size="small"
                                                                    placeholder="Name"
                                                                    variant="outlined"
                                                                    onChange={(e) => setAddressTitle(e.target.value)} />
                                                                <Label style={{ marginTop: '20px' }} htmlFor={'Address'}>Address</Label>

                                                                <Place
                                                                    apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                                                                    types={['address']}
                                                                    options={{
                                                                        types: ["address"],
                                                                    }}
                                                                    style={{
                                                                        width: '90%',
                                                                        borderRadius: '5px',
                                                                        fontSize: '14px',
                                                                        height: '20px',
                                                                        fontWeight: '400',
                                                                        fontFamily: 'Inter, sans-serif',
                                                                        color: '#344054',
                                                                        alignSelf: 'start',
                                                                        background: '#ffffff',
                                                                        border: '0.5px solid #d0d5dd',
                                                                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                                                        padding: '10px 14px',
                                                                    }}
                                                                    placeholder='Address'
                                                                    //onChange={onPlaceSelected}
                                                                    //onClick={onPlaceSelected}

                                                                    onPlaceSelected={(details) => {
                                                                        const street = `${details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('street_number')
                                                                        )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('route'),
                                                                        )?.short_name || ''}`;

                                                                        const zipCode = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('postal_code'),
                                                                        )?.short_name;
                                                                        const region = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('administrative_area_level_1'),
                                                                        )?.long_name;
                                                                        const city = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('locality'),
                                                                        )?.long_name;
                                                                        const country = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('country'),
                                                                        )?.long_name;
                                                                        setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address })
                                                                        console.log(details, zipCode, city, street, "details")
                                                                        // setAddressValue(details?.formatted_address)
                                                                        // setValues({ ...values, address: details?.formatted_address })
                                                                    }}

                                                                />

                                                                {/* <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between' }}>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"City"}
                                                                        value={address?.city}

                                                                        style={{ display: 'flex', alignSelf: 'center', }}
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        placeholder="City"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                                                                </div>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"Province"}
                                                                        value={address?.state}


                                                                        id="outlined-basic" size="small"
                                                                        placeholder="Province"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, state: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', paddingBottom: '25px' }}>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"Postal code"}
                                                                        value={address?.postalCode}

                                                                        style={{ display: 'flex', alignSelf: 'center', }}
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        placeholder="Postal code"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} />
                                                                </div>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"Country"}
                                                                        value={address?.country}


                                                                        id="outlined-basic" size="small"
                                                                        placeholder="Country"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, country: e.target.value })} />
                                                                </div>

                                                            </div> */}
                                                                <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '5%', justifyContent: 'flex-end', }}>
                                                                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, address: false })}>Cancel</RippleButton>
                                                                    <RippleButton onClick={async () => {
                                                                        await dispatch(saveAdditionalAddress({ ...address, "contactId": number, "title": addresstitle }))
                                                                        setAddressTitle('')
                                                                        init()
                                                                        setBoolValue({ ...boolValue, address: false })
                                                                    }}>Save</RippleButton>
                                                                </div>
                                                            </div>}
                                                    </CardContent>
                                                </Card>
                                                {contactDetail?.additionalAddress?.map((item) => <Card style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', paddingBottom: '0px', marginBottom: '20px', paddingTop: '0px', marginLeft: '20px', marginRight: '20px' }}  >
                                                    <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                        <Header>
                                                            <Status >
                                                                <LocationOnIcon style={{ fontSize: '12px' }} /> {item?.title}
                                                            </Status>
                                                            <Actions>
                                                                <DeleteOutlined style={{ cursor: 'pointer' }} onClick={async () => {
                                                                    await dispatch(deleteAdditionalAddress({
                                                                        id: number,
                                                                        addressId: item?._id
                                                                    }))
                                                                    init()
                                                                }} />
                                                            </Actions>
                                                        </Header>
                                                        <Label style={{ fontSize: '13px', marginBottom: '0px', color: palette.grey }}>{item?.address}</Label>
                                                    </CardContent>
                                                </Card>)}
                                            </>
                                        },
                                        {
                                            label: 'Quotes',
                                            icon: <RequestQuote style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        contactDetail?.quotes?.map((item, index) =>
                                                            item?.status &&
                                                            <TimelineItem key={index} style={{}}>
                                                                <TimelineSeparator>

                                                                    <TimelineDot style={{ width: '18px', height: '18px', background: item?.status === 'Waiting for confirmation' ? '#fa811f' : item?.status === 'Draft' ? '#586171' : item?.status === "Rejected" ? '#c0113d' : '#29740f' }}>
                                                                        <RequestQuoteOutlined style={{ fontSize: '18px', }} />
                                                                    </TimelineDot>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', paddingBottom: '0px', paddingTop: '0px' }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <Status color={getStatusColor(item?.status)}>
                                                                                    <span>●</span> {item?.status === 'Waiting for confirmation' ? 'Waiting' : item?.status === 'Rejected' ? 'Void' : item?.status}
                                                                                </Status>
                                                                                <Actions>
                                                                                    <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setModalData(item)
                                                                                        setType(true)
                                                                                        setModalOpenQuote(true)
                                                                                    }} />
                                                                                    {item?.status !== 'Rejected' && <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setDelete({
                                                                                            delete: true,
                                                                                            val: 'Rejected',
                                                                                            item: item,
                                                                                            type: 'Quote',
                                                                                            number: item?.numberOfQuote
                                                                                        })
                                                                                    }} />}


                                                                                </Actions>

                                                                            </Header>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`Quote #${item?.numberOfQuote}`}</h5>
                                                                                    <Label style={{ color: palette.grey, fontSize: '12px' }}>{moment(item?.date)?.format('DD MMM, hh:mm A')}</Label>
                                                                                </div>
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`$${putCommaInvalue(parseFloat(item?.totalAmount)?.toFixed(2))}`}</h5>

                                                                                </div>
                                                                            </div>
                                                                            <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                <h5>Line items</h5>
                                                                            </LineItemsToggle>
                                                                            {expanded === index && (
                                                                                <LineItemsContainer>
                                                                                    {item?.items?.map((item, idx) => (
                                                                                        <LineItem key={idx}>
                                                                                            <div>
                                                                                                <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                {item?.description && <p className="description">{item?.description}</p>}
                                                                                            </div>
                                                                                            <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                        </LineItem>
                                                                                    ))}
                                                                                </LineItemsContainer>
                                                                            )}
                                                                            <ProfileContainer>
                                                                                <div className='inner-container' variant="body2">
                                                                                    <Avatar className='avatar'>{getName(item.name)}</Avatar>
                                                                                    <Label className='label' >{item?.name}</Label>
                                                                                </div>
                                                                            </ProfileContainer>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        },
                                        {

                                            label: 'Jobs',
                                            icon: <Construction style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        contactDetail?.bookings?.map((item, index) =>

                                                            item?.bookingStatus &&
                                                            <TimelineItem key={index} style={{}}>
                                                                <TimelineSeparator>

                                                                    <TimelineDot style={{ width: '18px', height: '18px', background: item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f', }}>
                                                                        <Handyman style={{ fontSize: '18px', }} />
                                                                    </TimelineDot>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card onClick={() => {


                                                                    }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <Status color={item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f'}>
                                                                                    <span>●</span> {item?.bookingStatus === 'Waiting for confirmation' ? 'Waiting' : item?.bookingStatus}
                                                                                </Status>
                                                                                <Actions>
                                                                                    <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {

                                                                                        navigate(`/dashboard/servicerequests/${item?.referenceNo}`)

                                                                                    }} />
                                                                                    <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setDelete({
                                                                                            delete: true,
                                                                                            val: 'Rejected',
                                                                                            item: item,
                                                                                            type: 'Job',

                                                                                        })
                                                                                    }} />
                                                                                </Actions>
                                                                            </Header>

                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', textAlign: 'start' }}>{`${item?.customerAddress}`}</h5>
                                                                                    <Label>{item?.date ? `${moment(item?.date)?.format('DD MMM') || ''} at ${moment(item?.time)?.format('DD MMM') || ''}` : 'Unscheduled job'}</Label>
                                                                                </div>

                                                                            </div>

                                                                            {item?.items?.length > 0 && <>
                                                                                <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                    {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                    <h5>Line items</h5>
                                                                                </LineItemsToggle>
                                                                                {expanded === index && (
                                                                                    <LineItemsContainer>
                                                                                        {item?.items?.map((item, idx) => (
                                                                                            <LineItem key={idx}>
                                                                                                <div>
                                                                                                    <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                    <p className="description">{item?.description}</p>
                                                                                                </div>
                                                                                                <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                            </LineItem>
                                                                                        ))}
                                                                                    </LineItemsContainer>
                                                                                )}
                                                                            </>}
                                                                            <ProfileContainer>
                                                                                <div className='inner-container' variant="body2">
                                                                                    <Avatar className='avatar'>{getName(item.name)}</Avatar>
                                                                                    <Label className='label' >{item?.name}</Label>
                                                                                </div>
                                                                            </ProfileContainer>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        },
                                        {

                                            label: 'Invoices',
                                            icon: <Paid style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        contactDetail?.invoices?.map((item, index) =>
                                                            item?.invoiceStatus &&
                                                            <TimelineItem key={index} style={{}}>
                                                                <TimelineSeparator>

                                                                    <TimelineDot style={{ width: '18px', height: '18px', background: item?.invoiceStatus === 'Rejected' ? '#c0113d' : item?.invoiceStatus === 'Paid' ? '#29740f' : item?.invoiceStatus === 'Draft' ? '#586171' : '#fa811f' }}>
                                                                        <LocalAtm style={{ fontSize: '18px', }} />
                                                                    </TimelineDot>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card
                                                                        onClick={() => {
                                                                            // if (item?.invoiceStatus) {
                                                                            //     setModalData(item)
                                                                            //     setType(true)
                                                                            //     setModalOpenInvoice(true)
                                                                            // }

                                                                        }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', cursor: 'pointer' }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <Status color={item?.invoiceStatus === 'Rejected' ? '#c0113d' : item?.invoiceStatus === 'Paid' ? '#29740f' : item?.invoiceStatus === 'Draft' ? '#586171' : '#fa811f'}>
                                                                                    <span>●</span> {item?.invoiceStatus === 'Rejected' ? 'Void' : item?.invoiceStatus}
                                                                                </Status>
                                                                                <Actions>
                                                                                    <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setModalData(item)
                                                                                        setType(true)
                                                                                        setModalOpenInvoice(true)
                                                                                    }} />
                                                                                    {item?.invoiceStatus !== 'Rejected' && <DeleteOutlined
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            setDelete({
                                                                                                delete: true,
                                                                                                val: 'Void',
                                                                                                item: item,
                                                                                                type: 'Invoice',
                                                                                                number: item?.numberOfInvoice
                                                                                            })
                                                                                        }}
                                                                                    />}
                                                                                </Actions>
                                                                            </Header>

                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`Invoice #${item?.numberOfInvoice}`}</h5>
                                                                                    <Label style={{ color: palette.grey, fontSize: '12px' }}>{moment(item?.date)?.format('DD MMM, hh:mm A')}</Label>
                                                                                </div>
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`$${parseFloat(item?.totalAmount)?.toFixed(2)}`}</h5>

                                                                                </div>
                                                                            </div>
                                                                            <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                <h5>Line items</h5>
                                                                            </LineItemsToggle>
                                                                            {expanded === index && (
                                                                                <LineItemsContainer>
                                                                                    {item?.items?.map((item, idx) => (
                                                                                        <LineItem key={idx}>
                                                                                            <div>
                                                                                                <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                <p className="description">{item?.description}</p>
                                                                                            </div>
                                                                                            <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                        </LineItem>
                                                                                    ))}
                                                                                </LineItemsContainer>
                                                                            )}
                                                                            <ProfileContainer>
                                                                                <div className='inner-container' variant="body2">
                                                                                    <Avatar className='avatar'>{getName(item.name)}</Avatar>
                                                                                    <Label className='label' >{item?.name}</Label>
                                                                                </div>
                                                                            </ProfileContainer>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        }
                                    ]}
                                    value={value}

                                />


                            </StyleRight>

                        }
                    </Felxbox>

                </Main>}

            </Body>
            {isModalopen1 && <Modal
                onClose={() => setIsModalOpen1(false)}
                customStyle={{ background: '#fff' }}
            >
                <div >
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <Cancel style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => setIsModalOpen1(false)} />

                            <h4>Add New custom field</h4>
                        </div>
                    </HeaderContainer>
                    <Label htmlFor={'Email'}>Name</Label>
                    <Input
                        placeholder="Name"
                        type="text"
                        //value={editValue?.email}
                        style={{ height: 30, width: '300px' }}
                        onChange={(e) => {
                            setNewCustomField({
                                ...newCutomField, fieldName: e.target.value
                            })
                        }}

                    />



                </div>
                <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', marginBottom: '15px', justifyContent: 'flex-end', }}>
                    <SendButton1 style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setIsModalOpen1(false)}>Cancel</SendButton1>
                    <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                        const res = await dispatch(createNewCustomField({
                            "fields": [
                                newCutomField
                            ]
                        }))
                        setDataCustomField([...customField, newCutomField])
                        setCustomField([...selectCustomField, { fieldName: newCutomField?.fieldName, fieldValue: '' }])
                        setIsModalOpen1(false)
                    }}>Add</SendButton1>
                </div>
            </Modal>}

            {modalOpen && <RightModal
                customStyle={{ background: '#fff' }}
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <div style={{ height: '100%', display: 'flex' }}>
                    <RightSideConversations setConnection={(call) => {
                        // setConnection(call)
                    }} value={{ ...selectedNumber, number: selectedNumber?.to || selectedNumber?.number }} />
                </div>
            </RightModal>}
            {isModalopenInvoice && (
                <FullScreenModal onClose={() => setModalOpenInvoice(false)}>

                    <CreateInvoice
                        {...props}
                        type={type}
                        value={type ? { ...modalData, edit: true } : {
                            ...editValue, address: tempUserDetails?.address, email: tempUserDetails?.email, tempUserDetails: {
                                ...tempUserDetails, additionalAddress: [{ address: editValue?.address }, ...tempUserDetails?.additionalAddress], additionalContact: [{
                                    "name": tempUserDetails?.name,
                                    "number": tempUserDetails?.number,
                                    email: tempUserDetails?.email
                                }, ...tempUserDetails?.additionalContact]
                            }
                        }}
                        setModalOpenInvoice={() => setModalOpenInvoice(false)}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setModalOpenInvoice(false)


                        }
                        }
                    />


                </FullScreenModal>
            )}
            {isModalopenQuote && (
                <FullScreenModal onClose={() => setModalOpenQuote(false)}>

                    <CreateQuote
                        {...props}
                        value={type ? { ...modalData, edit: true } : {
                            ...editValue, address: tempUserDetails?.address, email: tempUserDetails?.email || '', tempUserDetails: {
                                ...tempUserDetails, additionalAddress: [{ address: editValue?.address }, ...tempUserDetails?.additionalAddress], email: tempUserDetails?.email || '', additionalContact: [{
                                    "name": tempUserDetails?.name,
                                    "number": tempUserDetails?.number,
                                    email: tempUserDetails?.email
                                }, ...tempUserDetails?.additionalContact]
                            }
                        }}

                        setModalOpenInvoice={() => setModalOpenQuote(false)}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setModalOpenQuote(false)


                        }
                        }
                    />


                </FullScreenModal>


            )}

            {isBookingModalopen && (
                <FullScreenModal onClose={() => setIsBookingModalOpen(false)}>

                    <CreateBooking
                        {...props}
                        serviceRequest={type}
                        newService={true}
                        value={type ? { ...modalData, edit: true, to: editValue?.to, customerAddress: editValue?.address } : editValue?.to ? editValue : {}}
                        additionalAddress={[{ address: editValue?.address }, ...tempUserDetails?.additionalAddress]}
                        setCreateBookingModal={(val) => {
                            setIsBookingModalOpen(false)
                            if (val?.number) {
                                navigate(`/dashboard/servicerequests/${val?.number}`)
                            }
                        }}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setIsBookingModalOpen(false)
                            if (val?.number) {
                                navigate(`/dashboard/servicerequests/${val?.number}`)
                            }

                        }
                        }
                    />


                </FullScreenModal>
            )}
            {isstartModalopen && <Modal

                onClose={() => setIsstartModalOpen(false)}
                customStyle={{ background: '#fff' }}
            >
                <div style={{ marginLeft: '-10px' }}>
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => setIsstartModalOpen(false)}>
                                <Icons src={cross} />
                            </CloseButton>

                        </div>
                    </HeaderContainer>
                    <CalendarStyle style={{ zIndex: 10000, }} className='p-3 d-flex justify-content-center'>
                        <div style={{ zIndex: 10000, borderRight: '1px solid #f3f3f3', marginRight: '15px', width: '350px', paddingRight: '-25px' }} >
                            <Calendar className='react-calendar'
                                value={values?.date}
                                onChange={(val) => setValues({ ...values, date: val })}
                                minDate={new Date()}
                            />

                        </div>
                        <Felxbox className='justify-content-between'>
                            <div style={{ display: 'inline', marginTop: '10px', marginRight: 10, }}>
                                {TimeListAm.map((item) => {
                                    return (
                                        <div onClick={() => setValues({ ...values, time: item })} style={{ padding: '5px', background: values?.time === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.time !== item ? '#000' : '#fff', fontSize: '14px', border: values?.time === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={{ display: 'inline', marginTop: '10px' }}>
                                {TimeListPM.map((item) => {
                                    return (
                                        <div onClick={() => setValues({ ...values, time: item })} style={{ padding: '5px', background: values?.time === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.time !== item ? '#000' : '#fff', border: values?.time === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', fontSize: '14px', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>

                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </Felxbox>
                    </CalendarStyle>
                    <ButtonBox>
                        <SendButton1 style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setIsstartModalOpen(false)}>Cancel</SendButton1>
                        <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                            setIsstartModalOpen(false)
                            // const res = await dispatch(createNewCustomField({
                            //     "fields": [
                            //         newCutomField
                            //     ]
                            // }))
                            // setDataCustomField([...customField, newCutomField])
                            // setIsModalOpen1(false)
                        }}>Save</SendButton1>
                    </ButtonBox>
                </div>
            </Modal>}
            {imagemodalOpen && (
                <Modal
                    isOpen={imagemodalOpen}
                    onClose={() => setImageModalOpen(false)}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <CloseButton onClick={() => setImageModalOpen(false)}>
                            <Icons src={cross} />
                        </CloseButton>

                    </div>

                    <Image onClick={() => {
                        window.open(previewURL)
                    }} src={previewURL} />


                </Modal>
            )}
            {
                isDelete?.delete && <TopModal
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    onClose={() => setDelete({})}
                >
                    <DeleteModal
                        value={isDelete}
                        onCancel={() => setDelete({})}
                        onSubmit={(val) => {
                            if (val === 'delete') {
                                setDelete({})
                                navigate(-1)
                            }
                            else {
                                setDelete({})
                                init()
                            }
                        }}
                    />
                </TopModal>
            }
            {
                boolValue?.editcontact && <TopModal
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    onClose={() => {
                        setBoolValue({ ...boolValue, editcontact: false })
                        setEditValue({
                            "name": tempUserDetails?.name || "",
                            "email": tempUserDetails?.email || "",
                            "address": tempUserDetails?.address || "",
                            "businessName": tempUserDetails?.businessName || '',
                            "to": tempUserDetails?.number || '',
                        })
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>
                            {"Edit contact"}
                        </h4>
                        <Cancel onClick={() => {
                            setBoolValue({ ...boolValue, editcontact: false })
                            setEditValue({
                                "name": tempUserDetails?.name || "",
                                "email": tempUserDetails?.email || "",
                                "address": tempUserDetails?.address || "",
                                "businessName": tempUserDetails?.businessName || '',
                                "to": tempUserDetails?.number || '',
                            })
                        }} style={{ cursor: 'pointer' }} />
                    </div>
                    <div style={{ paddingLeft: '15px', paddingRight: '20px', width: '90%' }}>


                        <InputBox
                            header={"Name"}
                            placeholder="Name"
                            type="text"
                            value={editValue?.name}

                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, name: e.target.value
                                })
                            }}

                        />
                        <InputBox
                            header={"Business name"}
                            placeholder="Business name"
                            type="text"
                            value={editValue?.businessName}

                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, businessName: e.target.value
                                })
                            }}

                        />

                        <InputBox
                            header={"Email"}
                            placeholder="Email"
                            type="text"
                            value={editValue?.email}

                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, email: e.target.value
                                })
                            }}

                        />

                        <Label style={{ marginTop: '20px', marginLeft: '3px' }} htmlFor={'Address'}>Street 1</Label>

                        <Place
                            apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                            types={['address']}
                            options={{
                                types: ["address"],
                            }}
                            defaultValue={address?.street}
                            style={{
                                width: '100%',
                                borderRadius: '5px',
                                fontSize: '14px',
                                height: '20px',
                                fontWeight: '400',
                                fontFamily: 'Inter, sans-serif',
                                color: '#344054',
                                alignSelf: 'start',
                                background: '#ffffff',
                                border: '0.5px solid #d0d5dd',
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                padding: '10px 14px',
                            }}
                            placeholder='street 1'
                            //onChange={onPlaceSelected}
                            //onClick={onPlaceSelected}

                            onPlaceSelected={(details) => {
                                const street = `${details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('street_number')
                                )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('route'),
                                )?.short_name || ''}`;

                                const zipCode = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('postal_code'),
                                )?.short_name;
                                const region = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('administrative_area_level_1'),
                                )?.long_name;
                                const city = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('locality'),
                                )?.long_name;
                                const country = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('country'),
                                )?.long_name;
                                setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address })
                                console.log(details, zipCode, city, street, "details")
                                // setAddressValue(details?.formatted_address)
                                // setValues({ ...values, address: details?.formatted_address })
                            }}

                        />
                        <InputBox
                            header={"Street 2"}
                            value={address?.street2}

                            style={{ display: 'flex', alignSelf: 'center', }}
                            id="outlined-basic"
                            size="small"
                            placeholder="Street 2"
                            variant="outlined"
                            onChange={(e) => setAddress({ ...address, street2: e.target.value })} />
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"City"}
                                    value={address?.city}

                                    style={{ display: 'flex', alignSelf: 'center', }}
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="City"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                            </div>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"Province"}
                                    value={address?.state}


                                    id="outlined-basic" size="small"
                                    placeholder="Province"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, state: e.target.value })} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '25px' }}>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"Postal code"}
                                    value={address?.postalCode}

                                    style={{ display: 'flex', alignSelf: 'center', }}
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="Postal code"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} />
                            </div>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"Country"}
                                    value={address?.country}


                                    id="outlined-basic" size="small"
                                    placeholder="Country"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, country: e.target.value })} />
                            </div>

                        </div>

                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '-20px', justifyContent: 'flex-end', }}>
                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                setBoolValue({ ...boolValue, editcontact: false })
                                setEditValue({
                                    "name": tempUserDetails?.name || "",
                                    "email": tempUserDetails?.email || "",
                                    "address": tempUserDetails?.address || "",
                                    "businessName": tempUserDetails?.businessName || '',
                                    "to": tempUserDetails?.number || '',
                                })
                            }}>Cancel</RippleButton>
                            <RippleButton onClick={() => {
                                editContact()
                                setBoolValue({ ...boolValue, editcontact: false })
                            }}>Save</RippleButton>
                        </div>
                    </div>
                </TopModal>
            }
        </>

    )
}
export default ContactDetails;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const CustomUrl = styled.input`
background: none;
border-radius: 8px;
cursor:pointer;
border:none;
height: 30px;
font-size:14px;

&:focus {
    outline: none;
    border: none; 
  }
`
const Main = styled.div`
padding-left:25px;
padding-right:25px;
width:100%;
height:99vh;
overflow-y:scroll;


`
const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
`;
const WrapperHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    margin-top:20px;
    margin-bottom:20px;
    .text{
        font-size:13px;
        text-align:start;
        margin-top:0px;
        margin-bottom:5px;
        line-height: 16px;

    }
`;
const Image = styled.img`
width:350px;
height:350px;
margin-top:20px;
margin-bottom:20px;

`
const Felxbox = styled.div`
display:flex;
align-items:start;
height:auto;
`
const CardContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-size: 13px;
  font-weight: 500;
`;

const Status = styled.h4`
  margin: 0;
  text-align: start;
  span {
    color: ${props => props.color};
    background: #fff;
    font-size: 18px;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 3px;
  svg {
    margin-right: 15px;
    font-size: 16px;
    &:last-child {
      margin-right: 0;
      font-size: 18px;
    }
  }
`;


const HeaderContainer = styled.div`
display:flex;

height:auto;
justify-content:space-between;
align-items:center; 
`
const CloseButton = styled.button`

display:flex;
align-items:center;
justify-content:center;
padding-left:10px;
border: none;
background:#ddd;
margin-top:15px;
width:30px;
height:30px;
align-self:center;
border-radius:25px;
font-size: 14px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const ValueName = styled.p`
color:${palette.charcoleBlack};

font-size: 14px;
text-align:start;
margin-top:0px;
margin-bottom:12px;
width:100%;

flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:10px;
margin-bottom:10px;
width: 60px;
height: 60px;

`
const OptionIcon = styled.img`
width: 13px;
height: 13px;
`
const PhoneFiled = styled(PhoneInput)`

--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;

.PhoneInputCountry{
    border: 0.5px solid #d0d5dd;
    height:21px;
    border-radius:4px;
    padding:8px;
    
}
.PhoneInputInput{
    height:35px;
    border-radius:4px;
    border: ${(props) => props.error ? '1px' : '0.5px'} solid ${(props) => props.error ? 'red' : '#d0d5dd'};
    &:focus {
        outline: none;
        border: 1.5px solid ${palette.charcoleBlack};
      
      }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-wrap: break-word; 
    overflow-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`
const WrapperContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
`;
const StyledStepLabel = styled(StepLabel)`

    && .MuiStepLabel-labelContainer {
       display:none;
    }
`;
const Selectbox = styled(Select)`
width:auto;
border-radius:8px;
text-align:start;
paddin-left:5px;
padding-right:5px;
margin-top:20px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #ddd;
background:white;
height:30px;
font-size:13px;
margin-bottom:20px;
:hover{
    background: #dddddd;
    border-radius: 4px;
}

.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyleRight = styled.div`
display:inline;
justify-content:flex-start;
align-self:start;
flex-direction:column;
overflow-y:scroll;
width:90%;
margin-right:15px;
margin-left:30px;
height:auto;
overflow-x:hidden;


`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid ${palette.smokeWhite};
background:white;
width:350px;
margin-top:20px;
border-radius:4px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
h5{
    margin-top:0px;
    margin-bottom:0px;
}
p{
    font-size: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
    margin-left:10px;
    margin-right:10px;
    line-height:16px;
}
.keyContainer{
    padding: 0px 0px 0px 10px;
    font-size:16px;
    font-weight:500;
    color:${palette.black};
    background:#f9f9f9;
display:flex;
justify-content:space-between;
border-bottom:1px solid ${palette.smokeWhite};
align-items:center;
}
.valueContainer{
    padding:7px;
    
    background:#fff;
    font-size:14px;
  
display:flex;
}
`
const EditContainer = styled.div`

border-left:1px solid  ${palette.smokeWhite};
display:flex;
align-items:center;
padding:10px;
cursor:pointer;

&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`

const LineItemsToggle = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
  h5 {
    text-align: start;
    margin: 0;
  }
  svg {
    margin-right: 5px;
  }
`;

const LineItemsContainer = styled.div`
  padding: 0 10px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-bottom:0px;
  
    }
    &.description {
      color: gray;
      margin-top:0px;
      margin-bottom:5px;
      line-height:16px;
      white-space:pre-line;
    }
    &.price {
      font-weight: 550;
    }
  }
`;

const MoreIcon = styled.img`


width:22px;
cursor:pointer;
color:black
`

const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  margin-top:10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const CommunicationInputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height:150px;
  max-height:80vh%;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: none;
  padding:0px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline:none;
    border: none;
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const SendButton1 = styled.button`
border:1px solid #4169e1;

 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`

const Input = styled.input`
height: 25px;
font-size: 14px;
padding-left:10px;
word-wrap: break-word;
width:100%;
display:block;
border-radius:5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Place = styled(Autocomplete)`
display:flex;
margin-right:0px;
margin-left:0px;
border-radius:5px;
font-size: 16px;
height:44px;
border-radius:4px;
border:1px solid  ${palette.charcoleBlack};
font-weight:400;
padding-left:12px;

&::placeholder {
  font-size:14px;
  font-weight:300;
}
&:focus {
    
    outline: 1.5px solid ${palette.charcoleBlack};
  
  }
`
const ContainerBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;

align-items:center;

font-weight:500;
cursor:pointer;

`
const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: start;
  font-size: 13px;
  margin-left: 10px;
  margin-right: 5px;
  .fieldName{
    width: 42%;
    font-weight: 600;
    font-size: 13px;
    text-align: start;
    line-height:16px;
    flex-wrap:wrap;
    word-break:break-all;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .fieldValue{
    width: 56%;
    font-size: 13px;
    line-height:16px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
  }
`;
const OptionBox = styled.div`
padding-left:15px;
padding-right:15px;
height:28px;
border-radius:15px;
border:1px solid #ddd;

display:flex;

font-size:13px;

align-items:center;
justify-content:center;
margin-right:15px;

font-weight:500;
cursor:pointer;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }

`
const ViewContainer = styled.div`
border:1px solid gray;
display:flex;
justify-content:center;
color:#000;
font-size:12px;
width:100px;
border-radius: 5px;
align-self:end;
cursor:pointer;
background:#fff;
padding:3px 10px 3px 10px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const FileInputButton = styled.label`
  display: inline-block;

  background-color: white; /* Professional Blue */
  color: #000; 
  font-size:14px;
  margin-bottom:10px;
  border-radius: 8px;
  border:1px solid gray;
  width:60px;
  height:60px;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 30px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const CalendarStyle = styled.div`
display:flex;
box-sizing: border-box;
background: #FFFFFF;

height:auto;
border-radius: 5px;
padding:15px;
margin-left:15px;
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  font-size:13px;
  background-color: #fff; 
  margin-bottom:15px;
  width:auto;
  :hover{
    background: #dddddd;

}

 `
const ProfileContainer = styled.div`
display: flex;
justify-content: flex-end;
align-content: flex-end;
align-items: center;
.inner-container {
    display: flex;
  }
  
  .avatar {
    width: 20px;
    height: 20px;
    background:${palette.newBlue}; /* Replace with your palette.newBlue */
    font-size: 10px;
    margin-right: 5px;
  }
  
  .label {
    font-weight: 500;
    margin-top: 2px;
    font-size:14px;
  }
  
`;