
import AccountSettings from '../../assets/icons/AccountSettings.png'
import security from '../../assets/icons/security.png'
import Taxes from '../../assets/icons/Taxes.png'
import privacy from '../../assets/icons/privacy.png'
import TermsandConditions from '../../assets/icons/TermsandConditions.png'
import logout from '../../assets/logout.png'
import ProductImg from '../../assets/product.png';

import { FileCopyOutlined, InsertDriveFileOutlined, LineStyleOutlined, LocalAtmOutlined, LoginOutlined, LogoutOutlined, ManageAccountsOutlined, ReceiptLongOutlined, SettingsOutlined, ShieldOutlined } from '@mui/icons-material'

import AccountBalance from '@mui/icons-material/AccountBalance';  // BalancesIcon
import Receipt from '@mui/icons-material/Receipt';  // PaymentDetailsIcon
import Payment from '@mui/icons-material/Payment';  // PaymentsIcon
import MonetizationOn from '@mui/icons-material/MonetizationOn';  // PayoutsIcon
import ListAlt from '@mui/icons-material/ListAlt';  // PayoutsListIcon
import PaymentSettingsIcon from '@mui/icons-material/SettingsOutlined'; 
export const DataList=[
   {
    title:'Account Settings',
    icon:'',
       data:[

         {
            title:'Account details',
            screen:'account-details',
            icon:AccountSettings,
            'outlined': ManageAccountsOutlined,
    
         },
         {
            title:'Login and security',
            screen:'login-and-security',
            icon:security,
            'outlined': LoginOutlined,
         },
    
       {
          title:'Tax details',
          screen:'tax-details',
          icon:Taxes,
          'outlined': ReceiptLongOutlined,
         },
    
         {
          title:'terms and conditions',
          screen:'terms-and-conditions',
          icon:Taxes,
          'outlined': InsertDriveFileOutlined,
         },
   
 
    
       ]
   },
{
    title:'Help',
    icon:'',
    data:[

     {
        title:'Terms of service',
        screen:'',
        icon:privacy,
        'outlined': FileCopyOutlined,
     },
     {
        title:'Privacy policy',
        screen:'',
        icon:TermsandConditions,
        'outlined': ShieldOutlined,
     },
     {
        title:'Log out',
        screen:'',
        icon:logout,
        'outlined': LogoutOutlined,
     },
   
    ]
}

]
export const DataListFB=[
   {
    title:'Account Settings',
    icon:'',
       data:[

         {
        title:'Account details',
        screen:'Account-details',
        icon:AccountSettings,
        'outlined': ManageAccountsOutlined,

     },
     {
        title:'Login and security',
        screen:'login-and-security',
        icon:security,
        'outlined': LoginOutlined,
     },

   {
      title:'Tax details',
      screen:'tax-details',
      icon:Taxes,
      'outlined': ReceiptLongOutlined,
     },

     {
      title:'terms-and-conditions',
      screen:'TaxDetails',
      icon:Taxes,
      'outlined': InsertDriveFileOutlined,
     },
   
 
    
       ]
   },
{
    title:'Help',
    icon:'',
    data:[

     {
        title:'Terms of service',
        screen:'',
        icon:privacy,
        'outlined': FileCopyOutlined,
     },
     {
        title:'Privacy policy',
        screen:'',
        icon:TermsandConditions,
        'outlined': ShieldOutlined,
     },
     {
        title:'Log out',
        screen:'',
        icon:logout,
        'outlined': LogoutOutlined,
        
     },
   
    ]
}

]

export const StripeDataList = [
   {
     title: 'Account Management',
     icon: ManageAccountsOutlined, 
   },
   {
     title: 'Payments',
     icon: Payment, 
   },
   {
     title: 'Payouts',
     icon: MonetizationOn, 
   },
   // {
   //   title: 'Documents',
   //   icon: SettingsOutlined,
   // }
 ];
 