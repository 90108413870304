import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const JobDetailsContainer = styled.div`
  border-radius: 10px;
  padding: 24px;
  max-width: 500px;

`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #1c3d5a;
  text-align:start;
  margin-bottom: 14px;
  margin-top:0px;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const Label = styled.span`
  font-size: 13px;
  font-weight: 550;
  text-align:start;
  color: #1c3d5a;
`;

const Value = styled.span`
  font-size: 13px;
  font-weight: 400;
  text-align:end;
  color: #666;
`;

const RecurringCard = (props) => {
    const {detail}=props
  return (
    <JobDetailsContainer>
      <Title>Job details</Title>
      <DetailItem>
        <Label>Job type</Label>
        <Value>Recurring job</Value>
      </DetailItem>
      <DetailItem>
        <Label>Started on</Label>
        <Value>{moment(detail?.date)?.format('DD MMM YYYY')}</Value>
      </DetailItem>
      <DetailItem>
        <Label>Lasts for</Label>
        <Value>{detail?.durationString}</Value>
      </DetailItem>
      {/* <DetailItem>
        <Label>Billing frequency</Label>
        <Value>Monthly on the last day of the month</Value>
      </DetailItem>
      <DetailItem>
        <Label>Billing type</Label>
        <Value>Per visit</Value>
      </DetailItem> */}
      <DetailItem>
        <Label>Schedule</Label>
        <Value>{detail?.schedule}</Value>
      </DetailItem>
      <DetailItem>
        <Label>Number of jobs</Label>
        <Value>{detail?.duration}</Value>
      </DetailItem>
    </JobDetailsContainer>
  );
};

export default RecurringCard;
